import { v4 as uuidv4 } from 'uuid';
import Player from './player';
import { PlacedCard, RevealedCard } from './monkeys-game';

interface MonkeysPlayerOptions {
  name: string;
  uid?: string;
  playable?: boolean;
  score?: number;
  placedCards?: number[];
  handCard?: number;
  isPlayersTurn?: boolean;
  ready?: boolean;
}


class MonkeysPlayer extends Player {
  private _playable: boolean;
  private _score: number;
  private _placedCards: (number | undefined)[];
  private _handCard?: number;
  private _isPlayersTurn: boolean;

  private _ready: boolean;

  static fromObject(obj: any): MonkeysPlayer {
    return new MonkeysPlayer({
      name: obj._name,
      uid: obj._uid,
      playable: obj._playable,
      score: obj._score,
      placedCards: obj._placedCards,
      handCard: obj._handCard,
      isPlayersTurn: obj._isPlayersTurn,
      ready: obj._ready,
    });
  }

  constructor(options: MonkeysPlayerOptions) {
    super({ name: options.name, uid: options.uid || uuidv4() });
    this._playable = options.playable || false;
    this._score = options.score || 0;
    this._placedCards = options.placedCards || [];
    this._handCard = options.handCard
    this._isPlayersTurn = options.isPlayersTurn || false;
    this._ready = options.ready || false;
  }

  maskedState(playerUid: string, startRound: boolean = false, endRound: boolean = false, revealedCard: RevealedCard | undefined, misplacedCard: PlacedCard | undefined): object {
    const cardMask = (card: number | undefined, index: number): number | undefined => {
      if (startRound && this.uid === playerUid && index % 2 === 1) return card;
      if (endRound) return card;
      if (revealedCard && revealedCard[0] === playerUid && revealedCard[1] === this.uid && revealedCard[2] === index) return card;
      if (misplacedCard && misplacedCard[0] === this.uid && misplacedCard[1] === index) return card;
      if (card === undefined) return undefined;
      return -1;
    }
    return {
      _name: this.name,
      _uid: this.uid,
      _playable: playerUid === this.uid,
      _score: this._score,
      _placedCards: this._placedCards.map((card, index) => cardMask(card, index)),
      _handCard: this.uid === playerUid ? this._handCard : this._handCard !== undefined ? -1 : -2,
      _isPlayersTurn: this._isPlayersTurn,
      _ready: this._ready,
    };
  }

  get score(): number {
    return this._score;
  }

  get currentScore(): number {
    return Array.from(new Set(this._placedCards.map(card => card ? card % 13 + 1 : 0))).reduce((acc, rank) => {
      if (rank === undefined) return acc;
      if (rank >= 10) return acc + 10;
      return acc + rank;
    }, 0);
  }

  set score(score: number) {
    this._score = score;
  }

  get placedCards(): (number | undefined)[] {
    return this._placedCards;
  }

  deal(cards: number[]): void {
    if (cards.length !== 4)
      return;
    this._placedCards = cards;
    this._handCard = undefined;
  }

  get handCard(): number | undefined {
    return this._handCard;
  }

  get ready(): boolean {
    return this._ready;
  }

  set ready(ready: boolean) {
    this._ready = ready;
  }

  drawCard(card: number): void {
    this._handCard = card;
  }

  get playable(): boolean {
    return this._playable;
  }

  get isPlayersTurn(): boolean {
    return this._isPlayersTurn;
  }

  set isPlayersTurn(isPlayersTurn: boolean) {
    this._isPlayersTurn = isPlayersTurn;
  }

  get stateInfo(): string {
    return this.name + ' ' + this.placedCards.map(card => card === undefined ? '' : (card % 13 + 1).toString()).join('/') + ' (' + this.currentScore + ')';
  }

  playCard(position: number): number {

    if (this._handCard === undefined) {
      console.error('No card in hand');
      return -1;
    }

    let card = -1;

    if (position === -1) {
      card = this._handCard;
    } else {
      if (this._placedCards[position] === undefined) {
        console.error('No card at position: ', position);
        return -1;
      }
      card = this._placedCards[position]!;
      this._placedCards[position] = this._handCard;
    }

    this._handCard = undefined;

    return card;
  }

  discardCard(position: number): number | undefined {
    if (!this._placedCards[position]) {
      console.error('No card at position: ', position);
      return;
    }

    let card = this._placedCards[position];
    this._placedCards[position] = undefined;
    if (position === this._placedCards.length - 1) {
      this._placedCards.pop();
    }

    return card;
  }

}

export default MonkeysPlayer;