import React, { useEffect } from 'react';
import './Daifugo.css';

import useWindowDimensions from '../hooks/windowDimension';

import SquaredDaifugoTable from '../DaifugoTable';
import CreateOrJoinDialog from '../components/Dialog';
import Game from 'shared/game_logic/daifugo-game';

import socket from '../socket';
import DaifugoMove, { DaifugoMoveType } from 'shared/game_logic/daifugo-move';
import Rules from 'shared/game_logic/rules';
import { GameType } from 'shared/game_logic/game';

function Daifugo() {
  const { height, width } = useWindowDimensions();
  const [game, setGameState] = React.useState<Game | undefined>(undefined);

  useEffect(() => {
    // Store socket.id in sessionStorage when connection established
    socket.on('connect', () => {
      const oldSocketId = sessionStorage.getItem('socketId');
      if (oldSocketId) {
        // Attempt to reconnect using the old socket ID
        console.log('Reconnecting with old socket ID:', oldSocketId);
        socket.emit('reconnect', oldSocketId, (game: object) => {
          console.log(game);
          setGameState(Game.fromObject(game));
        });
      }
      // Save new socket ID
      if (socket.id) {
        sessionStorage.setItem('socketId', socket.id);
      }
    });

    socket.on('playerJoined', (username: string, game: object) => {
      setGameState(Game.fromObject(game));
    });

    socket.on('gameStart', (game: object) => {
      setGameState(Game.fromObject(game));
    });

    socket.on('gameUpdate', (game: object) => {
      console.log(game);
      setGameState(Game.fromObject(game));
    });

    return () => {
      socket.off('connect');
      socket.off('playerJoined');
      socket.off('gameStart');
      socket.off('gameUpdate');
    }
  }, []);

  return (
    <div className="Daifugo">
      <CreateOrJoinDialog open={!game} gameType={GameType.DAIFUGO} handleCreate={(username: string, rules: Rules) => {
        const debug = window.location.search.includes('debug');
        socket.emit('create', {
          gameType: GameType.DAIFUGO,
          username: username,
          rulesObj: rules,
          debug: debug
        }, (game: object) => {
          console.log(game);
          setGameState(Game.fromObject(game));
        });
      }} handleJoin={(username: string) => {
        socket.emit('join', {
            gameType: GameType.DAIFUGO,
            username: username
          }, (game: object) => {
          console.log(game);
          setGameState(Game.fromObject(game));
        });
      }} />

      <SquaredDaifugoTable dimension={Math.min(height, width)} game={game} onMove={(cards: number[]) => {
        switch (game?.gamePhase) {
          case 'END_ROUND':
            socket.emit('move', new DaifugoMove({ type: DaifugoMoveType.READY }));
            break;
          case 'GIVE':
            socket.emit('move', new DaifugoMove({ type: DaifugoMoveType.GIVE, cards }));
            break;
          case 'DISCARD':
            socket.emit('move', new DaifugoMove({ type: DaifugoMoveType.DISCARD, cards }));
            break;
          case 'PLAY':
            socket.emit('move', new DaifugoMove({ type: DaifugoMoveType.PLAY, cards }));
            break;
          case 'EXCHANGE':
            socket.emit('move', new DaifugoMove({ type: DaifugoMoveType.EXCHANGE, cards }));
            break;
        }
      }} />
    </div>
  );
}

export default Daifugo;
