import { v4 as uuidv4 } from 'uuid';

import DaifugoRules from './daifugo-rules';
import DaifugoPlayer, { PlayerRank } from './daifugo-player';
import DaifugoMove, { DaifugoMoveType } from './daifugo-move';
import Table from './table';
import Card from './card';
import Game from './game';
import Player from './player';
import Move from './move';

enum DaifugoPhase {
  WAITING_FOR_PLAYERS = 'WAITING_FOR_PLAYERS',
  EXCHANGE = 'EXCHANGE',
  PLAY = 'PLAY',
  GIVE = 'GIVE',
  DISCARD = 'DISCARD',
  END_ROUND = 'END_ROUND',
  OVER = 'OVER',
}

interface DaifugoOptions {
  player1: DaifugoPlayer;
  player2?: DaifugoPlayer;
  player3?: DaifugoPlayer;
  player4?: DaifugoPlayer;
  table?: Table;

  rules?: DaifugoRules;

  debug?: boolean;

  currentPlayerUID?: string;
  gamePhase?: DaifugoPhase;
  revolution?: number;
  reverse?: boolean;
  skippedPlayers?: string[];
  finishedRound?: [string, string, string, string];
}

class DaifugoGame extends Game {
  private _table: Table;

  private _currentPlayerUid?: string;
  private _gamePhase: DaifugoPhase;
  private _revolution: number; // increase count for (re)*volution
  private _reverse: boolean;
  private _skippedPlayers: string[]; // current players that passed / have been skipped
  private _finishedRound: [string, string, string, string];
  // TODO private _lastMoveRanks: number[]; // to process multiple actions in case of staircase

  private _exchange?: [string, string, number[]][]; // player, receiver, cards

  static fromObject(obj: any): DaifugoGame {
    return new DaifugoGame({
      player1: DaifugoPlayer.fromObject(obj._players[0]),
      player2: obj._players[1] ? DaifugoPlayer.fromObject(obj._players[1]) : undefined,
      player3: obj._players[2] ? DaifugoPlayer.fromObject(obj._players[2]) : undefined,
      player4: obj._players[3] ? DaifugoPlayer.fromObject(obj._players[3]) : undefined,
      table: Table.fromObject(obj._table),
      rules: DaifugoRules.fromObject(obj._rules),
      currentPlayerUID: obj._currentPlayerUid,
      gamePhase: obj._gamePhase,
      revolution: obj._revolution,
      reverse: obj._reverse,
      skippedPlayers: obj._skippedPlayers,
      finishedRound: obj._finishedRound,
      debug: obj._debug,
    });
  }

  constructor(options: DaifugoOptions) {
    super({ firstPlayer: options.player1,
            rules: options.rules || DaifugoRules.default(),
            debug: options.debug || false });
    if (options.player2) this.addPlayer(options.player2);
    if (options.player3) this.addPlayer(options.player3);
    if (options.player4) this.addPlayer(options.player4);
    this._table = options.table || new Table({ cards: [] });

    this._currentPlayerUid = options.currentPlayerUID;
    if (this._currentPlayerUid) {
      if (!this.playerByUid(this._currentPlayerUid)) {
        throw new Error(`Player with uid ${this._currentPlayerUid} not found`);
      }
      this.currentPlayer!.isPlayersTurn = true;
    }
    this._gamePhase = options.gamePhase || DaifugoPhase.WAITING_FOR_PLAYERS;
    this._revolution = options.revolution || 0;
    this._reverse = options.reverse || false;
    this._skippedPlayers = options.skippedPlayers || [];
    this._finishedRound = options.finishedRound || ['', '', '', ''];
  }

  maskedGameStateObject(playerUid: string): object {

    const p1 = this.playerByUid(playerUid);
    const p1index = this.players.indexOf(p1!);
    if (!p1) {
      throw new Error(`Player with uid ${playerUid} not found`);
    }
    const p2 = this.players.length > 1 ? this.players[(p1index + 1) % this.players.length] : undefined;
    const p3 = this.players.length > 2 ? this.players[(p1index + 2) % this.players.length] : undefined;
    const p4 = this.players.length > 3 ? this.players[(p1index + 3) % this.players.length] : undefined;

    const players = [p1, p2, p3, p4].filter(p => p !== undefined);

    return {
      _players: players.map(p => p?.maskedState(playerUid, this.debug)),
      _table: this.table,
      _rules: this.rules,
      _debug: this.debug,
      _currentPlayerUid: this.currentPlayerUid,
      _gamePhase: this.gamePhase,
      _revolution: this.revolution,
      _reverse: this._reverse,
      _skippedPlayers: this._skippedPlayers,
      _finishedRound: this._finishedRound,
    };
  }

  get gamePhase(): DaifugoPhase {
    return this._gamePhase;
  }

  get readyToStart(): boolean {
    return this.players.length === 4;
  }

  get gameOngoing(): boolean {
    return this._gamePhase !== DaifugoPhase.WAITING_FOR_PLAYERS && this._gamePhase !== DaifugoPhase.OVER;
  }

  get gameOver(): boolean {
    return this._gamePhase === DaifugoPhase.OVER;
  }

  get revolution(): number {
    return this._revolution;
  }

  generateDeck(): number[] {
    const deck: number[] = [];
    for (let i = 0; i < 54; i++) {
      deck.push(i);
    }
    return deck;
  }

  shuffle(deck: number[]): number[] {
    for (let i = deck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [deck[i], deck[j]] = [deck[j], deck[i]];
    }
    return deck;
  }

  get player1(): DaifugoPlayer {
    return this.players[0];
  }

  get player2(): DaifugoPlayer | undefined {
    return this.players.length > 1 ? this.players[1] : undefined;
  }

  get player3(): DaifugoPlayer | undefined {
    return this.players.length > 2 ? this.players[2] : undefined
  }

  get player4(): DaifugoPlayer | undefined {
    return this.players.length > 3 ? this.players[3] : undefined;
  }

  get players(): DaifugoPlayer[] {
    return super.players as DaifugoPlayer[];
  }


  addPlayer(player: DaifugoPlayer): void {
    if (this.players.length >= 4) {
      throw new Error('Too many players');
    }

    super.addPlayer(player);
  }

  newPlayer(name: string): Player | undefined {
    if (this.players.length >= 4) {
      return;
    }

    const player = new DaifugoPlayer({ name, uid: uuidv4() });
    this.addPlayer(player);
    return player;
  }

  get currentPlayerUid(): string | undefined {
    return this._currentPlayerUid;
  }

  playerByUid(uid: string): DaifugoPlayer | undefined {
    return super.playerByUid(uid) as DaifugoPlayer;
  }

  get currentPlayer(): DaifugoPlayer | undefined {
    if (this._currentPlayerUid) {
      return this.playerByUid(this._currentPlayerUid);
    } else {
      return undefined;
    }
  }

  private set currentPlayer(player: DaifugoPlayer | undefined) {
    this._currentPlayerUid = player?.uid;
  }

  get table(): Table {
    return this._table;
  }

  get rules(): DaifugoRules {
    return super.rules as DaifugoRules;
  }

  get reverse(): boolean {
    return this._reverse;
  }

  get skippedPlayers(): string[] {
    return this._skippedPlayers;
  }

  get skipCount(): number {
    return this._skippedPlayers.length;
  }

  get finishedRound(): [string, string, string, string] {
    return this._finishedRound;
  }

  get stateInfo(): string {
    return this.table.cards.map(card => `${Card.getSuitChar(card)}${Card.getRank(card)}`).join(' ');
  }

  deal() {
    if (this.players.length < 4) {
      throw new Error('Not enough players to start game');
    }

    const deck = this.shuffle(this.generateDeck());
    this.player1.hand = deck.slice(0, 13).sort(Card.order);
    this.player2!.hand = deck.slice(13, 26).sort(Card.order);
    this.player3!.hand = deck.slice(26, 40).sort(Card.order); // TODO: P3 and P4 always get 14 cards
    this.player4!.hand = deck.slice(40, 54).sort(Card.order);

    this._table = new Table({ cards: [] });

    this.currentPlayer = this.startingPlayer;
    this.currentPlayer!.isPlayersTurn = true;

    this._gamePhase = DaifugoPhase.PLAY;
  }

  get startingPlayer(): DaifugoPlayer {
    let player = this.players.find(p => p.rank === PlayerRank.DAIHINMIN); // Daihinmin starts
    if (!player) {
      player = this.players.find(p => p.hand.includes(2)); // 3 of diamonds
    }
    return player!;
  }

  playMove(playerUid: string, move: Move, updateCallback: () => void, msgCallback: (msg: string, toAll: boolean) => void): boolean {
    if (!(move instanceof DaifugoMove)) {
      throw new Error('Invalid move');
    }
    const daifugoMove = move as DaifugoMove;
    switch (daifugoMove.type) {
      case DaifugoMoveType.READY:
        return this.playerReady(playerUid, updateCallback);
      case DaifugoMoveType.EXCHANGE:
        return this.exchangeCards(playerUid, daifugoMove, updateCallback);
      case DaifugoMoveType.PLAY:
        return this.playCards(playerUid, daifugoMove, updateCallback, msgCallback);
      case DaifugoMoveType.GIVE:
        return this.giveCards(playerUid, daifugoMove, updateCallback);
      case DaifugoMoveType.DISCARD:
        return this.discardCards(playerUid, daifugoMove, updateCallback);
    }
  }

  playerReady(playerUid: string, updateCallback: () => void): boolean {
    if (this._gamePhase !== DaifugoPhase.END_ROUND) {
      console.error('Not in end round phase');
      return false;
    }

    const player = this.playerByUid(playerUid);

    if (!player) {
      console.error(`Player with uid ${playerUid} not found`);
      return false;
    }

    player.ready = true;
    player.isPlayersTurn = false;

    if (this.players.every(p => p.ready)) {
      this.deal();
      this._gamePhase = DaifugoPhase.EXCHANGE;
      this._revolution = 0;
      this._reverse = false;
      this._skippedPlayers = [];
      this._finishedRound = ['', '', '', ''];
      const daihinmin = this.players.find(p => p.rank === PlayerRank.DAIHINMIN)!;
      daihinmin.selectedCards = daihinmin.hand
        .map((card, index) => ({ index, rank: Card.getRank(card) }))
        .sort((a, b) => b.rank - a.rank)
        .slice(0, 2)
        .map(card => card.index);
      const hinmin = this.players.find(p => p.rank === PlayerRank.HINMIN)!;
      hinmin.selectedCards = hinmin.hand
        .map((card, index) => ({ index, rank: Card.getRank(card) }))
        .sort((a, b) => b.rank - a.rank)
        .slice(0, 1)
        .map(card => card.index);
      this.currentPlayer = undefined;
      for (const p of this.players) {
        p.isPlayersTurn = true;
      }
    }

    return true;
  }

  exchangeCards(playerUid: string, move: DaifugoMove, updateCallback: () => void): boolean {
    if (this._gamePhase !== DaifugoPhase.EXCHANGE) {
      console.error('Not in exchange phase');
      return false;
    }

    const player = this.playerByUid(playerUid);

    if (!player) {
      console.error(`Player with uid ${playerUid} not found`);
      return false;
    }

    if (this._exchange && this._exchange.some(e => e[0] === player.uid)) {
      console.error('Player already exchanged cards');
      return false;
    }

    let cards = move.cards;

    if ((player.rank === PlayerRank.HINMIN || player.rank === PlayerRank.FUGO) && cards.length !== 1) {
      console.error('Hinmin and Fugo must give exactly 1 card');
      return false;
    }
    if ((player.rank === PlayerRank.DAIHINMIN || player.rank === PlayerRank.DAIFUGO) && cards.length !== 2) {
      console.error('Daihinmin and Daifugo must give exactly 2 cards');
      return false;
    }
    if (cards.some(card => !player.hand.includes(card))) {
      console.error('Player does not have all cards');
      return false;
    }
    if (player.rank === PlayerRank.DAIHINMIN || player.rank === PlayerRank.HINMIN) {
      const cardsMinRank = cards.map(Card.getRank).reduce((a, b) => a < b ? a : b);
      if (player.hand.filter(card => !cards.includes(card)).some(card => Card.getRank(card) > cardsMinRank)) {
        console.error('Daihinmin and Hinmin must give cards with highest rank');
        return false;
      }
    }

    const receiverRank = player.rank === PlayerRank.DAIHINMIN ? PlayerRank.DAIFUGO :
                          player.rank === PlayerRank.HINMIN ? PlayerRank.FUGO :
                          player.rank === PlayerRank.FUGO ? PlayerRank.HINMIN :
                          PlayerRank.DAIHINMIN;
    const receiver = this.players.find(p => p.rank === receiverRank);

    if (!receiver) {
      console.error(`Receiver with rank ${receiverRank} not found`);
      return false;
    }

    if (!this._exchange) {
      this._exchange = [];
    }
    this._exchange.push([player.uid, receiver.uid, cards]);
    player.hand = player.hand.filter(card => !cards.includes(card));
    player.selectedCards = [];
    player.isPlayersTurn = false;

    if (this._exchange.length === 4) {
      for (const [giverUid, receiverUid, cards] of this._exchange) {
        const giver = this.playerByUid(giverUid)!;
        const receiver = this.playerByUid(receiverUid)!;
        receiver.hand.push(...cards);
        receiver.hand.sort(Card.order);
        receiver.receivedCards.push(...cards.map(card => receiver.hand.indexOf(card)));
        giver.selectedCards = [];
        giver.isPlayersTurn = giver.rank === PlayerRank.DAIHINMIN;
      }

      this._exchange = undefined;
      this._gamePhase = DaifugoPhase.PLAY;
      this.currentPlayer = this.startingPlayer;
      this.currentPlayer!.isPlayersTurn = true;
    }

    return true;
  }

  playCards(playerUid: string, move: DaifugoMove, updateCallback: () => void, msgCallback: (msg: string, toAll: boolean) => void): boolean {
    if (this._gamePhase !== DaifugoPhase.PLAY) {
      console.error('Not in play phase');
      return false;
    }

    const player = this.playerByUid(playerUid);

    if (!player) {
      console.error(`Player with uid ${playerUid} not found`);
      return false;
    }

    if (!this.rules.isMoveLegal(this, player, move)) {
      msgCallback('Illegal move', false);
      return false;
    }

    const cards = move.cards;
    const topCards = this.table.topCards;
    const tableRank = this.table.currentRank;

    const skip = cards.length === 0;

    if (skip) {
      this._skippedPlayers.push(player.uid);

      this._table.skip(player.uid);

      if (this.skipCount === 3) {
        this._table = new Table({ cards: [] });
        this._skippedPlayers = [];
      }
    } else {
      this._skippedPlayers = [];

      player.hand = player.hand.filter(card => !cards.includes(card));
      this._table.push(player.uid, ...cards);

      if (this._table.playedTogether === undefined) {
        this._table.playedTogether = cards.length;
      }
    }

    console.log(`Player ${player.name} played ${cards.length} cards`);

    this.nextState(player, cards, updateCallback, topCards, tableRank);

    return true;
  }

  giveCards(playerUid: string, move: DaifugoMove, updateCallback: () => void): boolean {
    if (this._gamePhase !== DaifugoPhase.GIVE && this._gamePhase !== DaifugoPhase.EXCHANGE) {
      console.error('Not in give or exchange phase');
      return false;
    }

    const player = this.playerByUid(playerUid);

    if (!player) {
      console.error(`Player with uid ${playerUid} not found`);
      return false;
    }

    if (!this.rules.isMoveLegal(this, player, move)) {
      return false;
    }

    const cards = move.cards;
    const receiver = this.nextPlayerUnfinished(player)!;

    player.hand = player.hand.filter(card => !cards.includes(card));
    receiver.hand.push(...cards);
    receiver.hand.sort(Card.order);
    receiver.receivedCards.push(...cards.map(card => receiver.hand.indexOf(card)));

    this.nextState(player, cards, updateCallback);

    console.log(`Player ${player.name} gave ${cards.length} cards to ${this.nextPlayerUnfinished(player)!.name}`);

    return true;
  }

  discardCards(playerUid: string, move: DaifugoMove, updateCallback: () => void): boolean {
    if (this._gamePhase !== DaifugoPhase.DISCARD) {
      console.error('Not in discard phase');
      return false;
    }

    const player = this.playerByUid(playerUid);

    if (!player) {
      console.error(`Player with uid ${playerUid} not found`);
      return false;
    }

    if (!this.rules.isMoveLegal(this, player, move)) {
      return false;
    }

    const cards = move.cards;

    player.hand = player.hand.filter(card => !cards.includes(card));

    this.nextState(player, cards, updateCallback);

    console.log(`Player ${player.name} discarded ${cards.length} cards`);

    return true;
  }

  private nextState(player: DaifugoPlayer, cards: number[], updateCallback: () => void, prevTopCards?: number[], prevRank?: number) {
    var playedRank = this.table.isEmpty() || this.table.cards.slice(-1)[0] === -1 ? -1 : this.table.currentRank;
    // TODO: multiple ranks for staircase

    var isNextPlayerTurn = true;
    var tableClear = false;


    switch (this._gamePhase) {
      case DaifugoPhase.GIVE:
        this._gamePhase = DaifugoPhase.PLAY;
        break;

      case DaifugoPhase.DISCARD:
        this._gamePhase = DaifugoPhase.PLAY;
        break;

      case DaifugoPhase.PLAY:
        if (cards.length === 0) {
          break;
        }

        if (this.rules.tightRank) {
          var adjacentRank = prevRank! + ((this.revolution % 2 === 1) !== this.table.backward ? -1 : 1);
          if (playedRank === adjacentRank) {
            this.table.tightRank = playedRank;
            console.log('Tight rank!');
          }
        }

        if (this.rules.tightSuits) {
          var tableSuits = prevTopCards!.filter(card => card >= 0).map(card => Card.getSuit(card));
          var tightSuits = this.table.tightSuits;
          for (var card of cards) { // find additional tight suits
            var suit = Card.getSuit(card);
            if (suit !== 'JOKER' && tableSuits.includes(suit) && !tightSuits.includes(suit)) {
              tightSuits.push(suit);
              console.log('New tight suit:', suit);
            }
          }
          this.table.tightSuits = tightSuits;
        }

        // CARD EFFECTS

        if (this.rules.n7give && playedRank === 7) {
          if (player.hand.length !== 0) {
            this._gamePhase = DaifugoPhase.GIVE;
            isNextPlayerTurn = false;
          }
        }

        if (this.rules.n8clear && playedRank === 8) {
          tableClear = true;
          if (player.hand.length !== 0) {
            isNextPlayerTurn = false;
          }
        }

        if (this.rules.n9reverse && playedRank === 9) {
          this._reverse = !this._reverse;
        }

        if (this.rules.n10burn && playedRank === 10) {
          if (player.hand.length !== 0) {
            this._gamePhase = DaifugoPhase.DISCARD;
            isNextPlayerTurn = false;
          }
        }

        if (this.rules.n11back && playedRank === 11) {
          this.table.backward = !this.table.backward;
        }

        if (this.rules.revolution && cards.length === 4) {
          this._revolution++;
        }

        for (const p of this.players) {
          p.receivedCards = [];
        }

        break;
    }

    if (isNextPlayerTurn) {

      this.currentPlayer!.isPlayersTurn = false;
      var n5skips = this.rules.n5skip && playedRank === 5 ? cards.length : 0;
      for (let i = 0; i < 4; i++) {
        this.currentPlayer = this.nextPlayer(this.currentPlayer!);
        if (!this.finishedRound.includes(this.currentPlayer!.uid)) {
          if (n5skips === 0) {
            this.currentPlayer!.isPlayersTurn = true;
            break;
          }
          n5skips--;
        }
        
        this._skippedPlayers.push(this.currentPlayer!.uid);
        this._table.skip(player.uid);
        if (this.skipCount === 3) {
          tableClear = true;
        }
      }
    }

    if (tableClear) {
      const currPlayer = this.currentPlayer!;
      this.currentPlayer!.isPlayersTurn = false;
      this.currentPlayer = undefined;
      setTimeout(() => {
        this._table = new Table({ cards: [] });
        this._skippedPlayers = [];
        this.currentPlayer = currPlayer;
        this.currentPlayer!.isPlayersTurn = true;
        updateCallback();
      }, 1000);
    }

    this.checkFinish(player);
  }

  checkFinish(player: DaifugoPlayer) {
    // FINISH HAND / GAME
    
    if (player.hand.length === 0) {
      for (let i = 0; i < 4; i++) {
        if (this._finishedRound[i] === '') {
          this._finishedRound[i] = player.uid;
          break;
        }
      }
    }

    if (this._finishedRound.filter(p => p !== '').length === 3) {
      this._gamePhase = DaifugoPhase.END_ROUND;
      var playerUids = new Array(this.player1.uid, this.player2!.uid, this.player3!.uid, this.player4!.uid);
      this._finishedRound[3] = playerUids.filter(p => !this._finishedRound.includes(p))[0];
      for (const p of this.players) {
        p.ready = false;
        p.isPlayersTurn = true;
        const pFinish = this._finishedRound.indexOf(p.uid);
        if (pFinish === 0) {
          p.rank = PlayerRank.DAIFUGO;
        } else if (pFinish === 1) {
          p.rank = PlayerRank.FUGO;
        } else if (pFinish === 2) {
          p.rank = PlayerRank.HINMIN;
        } else {
          p.rank = PlayerRank.DAIHINMIN;
        }
      }
      this.currentPlayer = undefined;
    }
  }

  nextPlayer(player: DaifugoPlayer): DaifugoPlayer | undefined {
    if (this.reverse) {
      return this.players[(this.players.indexOf(player) + this.players.length - 1) % this.players.length];
    } else {
      return this.players[(this.players.indexOf(player) + 1) % this.players.length];
    }
  }

  prevPlayer(player: DaifugoPlayer): DaifugoPlayer | undefined {
    if (this.reverse) {
      return this.players[(this.players.indexOf(player) + 1) % this.players.length];
    } else {
      return this.players[(this.players.indexOf(player) + this.players.length - 1) % this.players.length];
    }
  }

  nextPlayerUnfinished(player: DaifugoPlayer): DaifugoPlayer | undefined {
    var p = player;
    do {
      p = this.nextPlayer(p)!;
    } while (this.finishedRound.includes(p.uid));
    return p;
  }

}

export default DaifugoGame;