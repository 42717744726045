import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Daifugo from './pages/Daifugo';
import Monkeys from './pages/Monkeys';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/daifugo" element={<Daifugo />} />
          <Route path="/monkeys" element={<Monkeys />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
