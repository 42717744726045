import DaifugoGame from './daifugo-game';
import DaifugoPlayer from './daifugo-player';
import Table from './table';
import Card from './card';
import Rules from './rules';
import DaifugoMove from './daifugo-move';

class DaifugoRulesOptions {
  n5skip?: boolean;
  n7give?: boolean;
  n8clear?: boolean;
  n9reverse?: boolean;
  n10burn?: boolean;
  n11back?: boolean;
  joker?: boolean;
  tightSuits?: boolean;
  tightRank?: boolean;
  revolution?: boolean;
}

class DaifugoRules extends Rules {
  private _n5skip: boolean;
  private _n7give: boolean;
  private _n8clear: boolean;
  private _n9reverse: boolean;
  private _n10burn: boolean;
  private _n11back: boolean;
  private _joker: boolean;
  private _tightSuits: boolean;
  private _tightRank: boolean;
  private _revolution: boolean;

  static fromObject(obj: any): DaifugoRules {
    return new DaifugoRules({
      n5skip: obj._n5skip,
      n7give: obj._n7give,
      n8clear: obj._n8clear,
      n9reverse: obj._n9reverse,
      n10burn: obj._n10burn,
      n11back: obj._n11back,
      joker: obj._joker,
      tightSuits: obj._tightSuits,
      tightRank: obj._tightRank,
      revolution: obj._revolution,
    });
  }

  static default(): DaifugoRules {
    return new DaifugoRules({
      n5skip: true,
      n7give: true,
      n8clear: true,
      n9reverse: true,
      n10burn: true,
      n11back: true,
      joker: true,
      tightSuits: true,
      tightRank: true,
      revolution: true,
    });
  }

  constructor(options: DaifugoRulesOptions) {
    super();
    this._n5skip = options.n5skip || false;
    this._n7give = options.n7give || false;
    this._n8clear = options.n8clear || false;
    this._n9reverse = options.n9reverse || false;
    this._n10burn = options.n10burn || false;
    this._n11back = options.n11back || false;
    this._joker = options.joker || false;
    this._tightSuits = options.tightSuits || false;
    this._tightRank = options.tightRank || false;
    this._revolution = options.revolution || false;
  }

  get minPlayers(): number {
    return 4;
  }

  get maxPlayers(): number {
    return 4;
  }

  get numPlayers(): number | undefined {
    return 4;
  }

  get n5skip(): boolean {
    return this._n5skip;
  }

  get n7give(): boolean {
    return this._n7give;
  }

  get n8clear(): boolean {
    return this._n8clear;
  }

  get n9reverse(): boolean {
    return this._n9reverse;
  }

  get n10burn(): boolean {
    return this._n10burn;
  }

  get n11back(): boolean {
    return this._n11back;
  }

  get joker(): boolean {
    return this._joker;
  }

  get tightSuits(): boolean {
    return this._tightSuits;
  }

  get tightRank(): boolean {
    return this._tightRank;
  }

  get revolution(): boolean {
    return this._revolution;
  }

  get botCompatible(): boolean {
    return this.n5skip && this.n7give && this.n8clear && this.n9reverse && this.n10burn && this.n11back && this.joker && this.tightSuits && this.tightRank && this.revolution;
  }

  isPlayersTurn(game: DaifugoGame, player: DaifugoPlayer): boolean {
    if (!player.isPlayersTurn) {
      console.error('Not player', player.name, '\'s turn but', game.currentPlayer?.name, '\'s turn');
      return false;
    }
    return true;
  }

  canPlayCards(game: DaifugoGame, player: DaifugoPlayer, cards: number[]): boolean {
    if (cards.length === 0 && game.table.isEmpty()) {
      console.error('Cannot skip first turn');
      return false;
    }
    if (!cards.every(card => 0 <= card && card < 54)) {
      console.error('Invalid card(s) played');
      return false;
    }
    if (game.table.playedTogether && cards.length > 0 && game.table.playedTogether !== cards.length) {
      console.error('Number of cards played does not match current table');
      return false;
    }
    if (!cards.every(card => player.hand.includes(card))) {
      console.error('Player does not have all cards');
      return false;
    }
    return true;
  }

  matchesTightSuits(table: Table, cards: number[]): boolean {
    const suits = table.tightSuits;
    var cs = cards.slice();
    for (const suit of suits) {
      var c = cs.find(card => Card.getSuit(card) === suit);
      if (c === undefined) {
        c = cs.find(card => Card.getSuit(card) === 'JOKER');
      }
      if (c === undefined) {
        console.error('Suit', suit, 'not found in cards', cs);
        return false;
      }
      cs = cs.filter(card => card !== c);
    }

    return true;
  }

  matchesTightRank(game: DaifugoGame, cards: number[]): boolean {
    if (game.table.tightRank === 0) {
      return true;
    }

    const rankToMatch = (game.revolution % 2 === 1) !== game.table.backward ? game.table.currentRank - 1 : game.table.currentRank + 1;
    for (const card of cards) {
      if (Card.getSuit(card) === 'JOKER') {
        continue;
      }
      if (Card.getRank(card) !== rankToMatch) {
        console.error('Card', card, `{r${Card.getRank(card)}}`, 'does not match required rank', rankToMatch);
        return false;
      }
    }
    return true;
  }


  isMoveLegal(game: DaifugoGame, player: DaifugoPlayer, move: DaifugoMove): boolean {
    if (!this.isPlayersTurn(game, player)) {
      return false;
    }

    const cards = move.cards;

    if (move.type === 'play') {

      if (!this.canPlayCards(game, player, cards)) {
        return false;
      }

      if (cards.length === 0) {
        return true;
      }

      if (cards.length > 4) {
        console.error('Cannot play more than 4 cards');
        return false;
      }

      var rank = Card.getRank(cards.every(card => Card.getSuit(card) === 'JOKER') ? 52 : cards[0]);

      if (game.table.currentRank === 16) {
        console.error('Cannot play cards on joker');
        return false;
      }

      for (const card of cards) {
        if (Card.getRank(card) !== rank && Card.getSuit(card) !== 'JOKER') {
          console.error('All cards must have the same rank');
          console.error('cards:', cards, 'ranks:', cards.map(card => Card.getRank(card)), 'rank:', rank, 'table', game.table.cards, 'tableRank:', game.table.currentRank);
          return false;
        }
      }

      if (game.table.currentRank > 0 && rank >= game.table.currentRank && rank !== 16) {
        if (game.table.backward && game.revolution % 2 === 0) {
          console.error('Must play lower rank if table is backward');
          return false;
        }
        if (!game.table.backward && game.revolution % 2 === 1) {
          console.error('Must play lower rank during revolution if table is not backward');
          return false;
        }
      }

      if (game.table.currentRank > 0 && rank <= game.table.currentRank && rank !== 16) {
        if (!game.table.backward && game.revolution % 2 === 0) {
          console.error('Must play higher rank if table is not backward');
          return false;
        }
        if (game.table.backward && game.revolution % 2 === 1) {
          console.error('Must play higher rank during revolution if table is backward');
          return false;
        }
      }

      if (this.tightSuits && !this.matchesTightSuits(game.table, cards)) {
        return false;
      }

      if (this.tightRank && !this.matchesTightRank(game, cards)) {
        return false;
      }
      
      return true;
    } else if (move.type === 'give') {
      if (!this.n7give) {
        console.error('Cannot give cards');
        return false;
      }

      if (cards.length > game.table.playedTogether!) {
        console.error('Cannot give more cards than 7s played');
        return false;
      }

      if (!cards.every(card => player.hand.includes(card))) {
        console.error('Player does not have all cards');
        return false;
      }

      return true;
    } else if (move.type === 'discard') {
      if (!this.n10burn) {
        console.error('Cannot discard cards');
        return false;
      }

      if (cards.length > game.table.playedTogether!) {
        console.error('Cannot discard more cards than 10s played');
        return false;
      }

      if (!cards.every(card => player.hand.includes(card))) {
        console.error('Player does not have all cards');
        return false;
      }

      return true;
    }

    return false;
  }
}

export default DaifugoRules;