import Move from "./move";

enum MonkeysMoveType {
  READY = 'ready',
  DRAW = 'draw',
  PLACE = 'place',
  ACTION = 'action',
  MONKEYS = 'monkeys',
}

interface MonkeysMoveOptions {
  type: MonkeysMoveType;
  card?: number;
  targetUid?: string;
  targetCard?: number;
}

class MonkeysMove extends Move {
  private _card?: number;
  private _targetUid?: string;
  private _targetCard?: number;

  static fromObject(obj: any): MonkeysMove {
    return new MonkeysMove({
      type: obj._type,
      card: obj._card,
      targetUid: obj._targetUid,
      targetCard: obj._targetCard,
    });
  }

  constructor(options: MonkeysMoveOptions) {
    super(options.type.toString());
    this._card = options.card;
    this._targetUid = options.targetUid;
    this._targetCard = options.targetCard;
  }

  get strType(): string {
    return this.type.toString();
  }

  get type(): MonkeysMoveType {
    const type = super.strType as MonkeysMoveType;
    if (!type) {
      throw new Error('Invalid MonkeysMoveType');
    }
    return type;
  }

  get card(): number | undefined {
    return this._card;
  }

  get targetUid(): string | undefined {
    return this._targetUid;
  }

  get targetCard(): number | undefined {
    return this._targetCard;
  }
}

export { MonkeysMoveType };
export default MonkeysMove;