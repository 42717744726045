import Rules from "./rules";

interface MonkeysRulesOptions {
  numPlayers?: number;
  maxRounds?: number;
  scoreToLose: number;
  penalty: number;
}

class MonkeysRules extends Rules {
  // TODO: multiple decks, max players, etc.

  private _numPlayers: number | undefined;
  private _maxRounds: number | undefined;
  private _scoreToLose: number;
  private _penalty: number;

  // TODO: _reshuffle: boolean; // option to reshuffle discarded cards back into deck

  constructor(options: MonkeysRulesOptions) {
    super();
    this._numPlayers = options.numPlayers;
    this._maxRounds = options.maxRounds;
    this._scoreToLose = options.scoreToLose;
    this._penalty = options.penalty;
  }

  static fromObject(obj: any): MonkeysRules {
    return new MonkeysRules({
      numPlayers: obj._numPlayers,
      maxRounds: obj._maxRounds,
      scoreToLose: obj._scoreToWin,
      penalty: obj._penalty,
    })
  }

  static default(): MonkeysRules {
    return new MonkeysRules({
      numPlayers: 4,
      maxRounds: undefined,
      scoreToLose: 100,
      penalty: 20,
    });
  }

  get botCompatible(): boolean {
    return false;
  }

  get minPlayers(): number {
    return 2;
  }

  get maxPlayers(): number {
    return 6;
  }

  get numPlayers(): number | undefined {
    return this._numPlayers;
  }

  get maxRounds(): number | undefined {
    return this._maxRounds;
  }

  get scoreToLose(): number {
    return this._scoreToLose;
  }

  get penalty(): number {
    return this._penalty;
  }
}

export default MonkeysRules;