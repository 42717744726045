import React, { CSSProperties } from 'react';
import styles from './ScoreBoard.module.css';
import MonkeysPlayer from 'shared/game_logic/monkeys-player';

interface ScoreBoardProps {
  scores: { [playerUid: string]: number }[];
  players: MonkeysPlayer[];
  dimension: number;
}

const ScoreBoard: React.FC<ScoreBoardProps> = ({ scores, players, dimension }) => {
  return (
    <div className={styles.scoreBoardContainer}
      style={{
        position: 'absolute',
        bottom: `${0.02 * dimension}px`,
        left: `${0.02 * dimension}px`,
        '--board-width-px': `${0.25 * dimension}px`,
        '--board-height-px': `${0.3 * dimension}px`,
      } as CSSProperties}>
      <div className={styles.trigger}>
        Scoreboard ▲
      </div>
      <div className={styles.scoreBoard}
        style={{
          width: `${0.25 * dimension}px`,
          height: `${0.3 * dimension}px`,
        }}>
        <div className={styles.title}>Score</div>
        <div className={styles.scrollContainer}>
          <div className={styles.headers}>
            <div className={styles.roundNumber}>#</div>
            {players.map(player => {
              const shortName = player.name.slice(0, 2);
              return (
                <div key={player.uid} className={styles.playerName}>
                  {shortName}
                </div>
              );
            })}
          </div>
          <div className={styles.scores}>
            {scores.map((scoreRound, roundIndex) => (
              <div key={roundIndex} className={styles.round}>
                <div className={styles.roundNumber}>{roundIndex + 1}</div>
                {players.map(player => (
                  <div key={player.uid} className={styles.score}>
                    {scoreRound[player.uid] || '-'}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};


export default ScoreBoard;