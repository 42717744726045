import Rules from './rules';
import Move from './move';
import Player from './player';

enum GameType {
  DAIFUGO = 'daifugo',
  MONKEYS = 'monkeys',
}

interface GameOptions {
  firstPlayer?: Player;
  rules?: Rules;
  debug?: boolean;
}

abstract class Game {
  private _players: Player[];
  private _rules: Rules;
  private _debug: boolean;

  constructor(options: GameOptions) {
    this._players = options.firstPlayer ? [options.firstPlayer] : [];
    this._rules = options.rules || Rules.default();
    this._debug = options.debug || false;
  }

  get players(): Player[] {
    return this._players;
  }

  get debug(): boolean {
    return this._debug;
  }

  get rules(): Rules {
    return this._rules;
  }

  addPlayer(player: Player): void {
    this._players.push(player);
  }

  abstract newPlayer(name: string): Player | undefined;

  playerByUid(uid: string): Player | undefined {
    return this._players.find(player => player.uid === uid);
  }

  abstract maskedGameStateObject(playerUid: string): object;

  abstract get readyToStart(): boolean;

  abstract get gameOngoing(): boolean;

  abstract get gameOver(): boolean;

  abstract deal(): void;

  abstract get currentPlayerUid(): string | undefined;

  abstract get currentPlayer(): Player | undefined;

  abstract playMove(playerUid: string, move: Move, updateCallback: () => void, msgCallback: (msg: string, toAll: boolean) => void): boolean;

  abstract get stateInfo(): string;
}

export { GameType };
export default Game;