import Card from "./card";
import { Suit } from "./card";

interface TableOptions {
  cards: number[];
  playedBy?: string[];
  playedTogether?: number;

  backward?: boolean;
  tightSuits?: [boolean, boolean, boolean, boolean];
  tightRank?: number;
}

class Table {
  private _cards: number[];
  private _playedBy: string[];
  private _playedTogether?: number;
  // table's current restrictions
  private _backward: boolean;
  private _tightSuits: [boolean, boolean, boolean, boolean];
  private _tightRank: number;

  static fromObject(obj: any): Table {
    return new Table({
      cards: obj._cards,
      playedBy: obj._playedBy,
      playedTogether: obj._playedTogether,
      backward: obj._backward,
      tightSuits: obj._tightSuits,
      tightRank: obj._tightRank,
    });
  }

  constructor(options: TableOptions) {
    this._cards = options.cards;
    this._playedBy = options.playedBy || [];
    this._playedTogether = options.playedTogether;

    this._backward = options.backward || false;
    this._tightSuits = options.tightSuits || [false, false, false, false];
    this._tightRank = options.tightRank || 0;
  }

  get cards(): number[] {
    return this._cards;
  }

  /**
   * Returns the top cards on the table, excluding skip dummy cards.
   */
  get topCards(): number[] {
    return this.cards.filter(card => card !== -1).slice(- (this._playedTogether || 0));
  }

  get playedBy(): string[] {
    return this._playedBy;
  }

  push(playerUid: string, ...cards: number[]) {
    this._cards.push(...cards);
    this._playedBy.push(...cards.map(() => playerUid));
  }

  skip(playerUid: string) {
    for (let i = 0; i < (this._playedTogether || 0); i++) {
      this._cards.push(-1);
    }
    this._playedBy.push(...Array(this._playedTogether).fill(playerUid));
  }

  isEmpty(): boolean {
    return this._cards.length === 0;
  }

  get playedTogether(): number | undefined {
    return this._playedTogether;
  }

  set playedTogether(playedTogether: number | undefined) {
    this._playedTogether = playedTogether;
  }

  get backward(): boolean {
    return this._backward;
  }

  set backward(backward: boolean) {
    this._backward = backward;
  }

  get tightSuits(): Suit[] {
    return [Suit.CLUBS, Suit.SPADES, Suit.HEARTS, Suit.DIAMONDS].filter((_, i) => this._tightSuits[i]);
  }

  set tightSuits(tightSuits: Suit[]) {
    this._tightSuits = [false, false, false, false];
    tightSuits.forEach(suit => {
      this._tightSuits[[Suit.CLUBS, Suit.SPADES, Suit.HEARTS, Suit.DIAMONDS].indexOf(suit)] = true;
    });
  }

  get isTightRank(): boolean {
    return this._tightRank > 0;
  }

  get tightRank(): number {
    return this._tightRank;
  }

  set tightRank(tightRank: number) {
    this._tightRank = tightRank;
  }

  /**
   * Returns the current rank of the table.
   */
  get currentRank(): number {
    if (this.isEmpty()) {
      return 0;
    }
    const topCardsAreJokers = this.topCards.every(card => Card.getSuit(card) === Suit.JOKER);
    if (topCardsAreJokers) {
      if (this.isTightRank) {
        return this.tightRank;
      } else {
        return 16; // Joker
      }
    }
    return Card.getRank(this.topCards.find(card => card < 52)!);
  }

  get currentSuits(): Suit[] {
    return [Suit.CLUBS, Suit.SPADES, Suit.HEARTS, Suit.DIAMONDS].filter(suit => this.topCards.find(card => Card.getSuit(card) === suit));
  }
}

export default Table;