import Game from 'shared/game_logic/daifugo-game';
import Player from 'shared/game_logic/daifugo-player';
import Card, { Suit } from 'shared/game_logic/card';
import React, { ReactElement, useEffect } from 'react';

import styles from './DaifugoTable.module.css';

const cardImages = Array.from({ length: 54 }, (_, i) => `/assets/cards/${i}.png`);
const cardBack = '/assets/cards/back.png';



interface DaifugoTableProps {
  dimension: number;
  game: Game | undefined;
  onMove: (cards: number[]) => void;
  onHover?: (card: number) => void; //TODO: idea is to sync the hovered card as small animation to other players
  onCardSelect?: (card: number) => void; //TODO: same as above
}

enum Position {
  BOTTOM,
  LEFT,
  TOP,
  RIGHT,
}

interface HandProps {
  position: Position;
  tableDimension: number;
  game?: Game;
  player: Player;
  selectedCards?: number[];
  setSelectedCards?: (cards: number[]) => void;
  triggerMove?: () => void;
}

function getPlayerPos(game: Game, playerUid: string): Position | undefined {
  if (game.player1.uid === playerUid) return Position.BOTTOM;
  if (game.player2?.uid === playerUid) return Position.LEFT;
  if (game.player3?.uid === playerUid) return Position.TOP;
  if (game.player4?.uid === playerUid) return Position.RIGHT;
  return undefined;
}

function SquaredDaifugoTable({ dimension, game, onMove, onHover, onCardSelect }: DaifugoTableProps): ReactElement {
  const td = dimension;

  const [selectedCards, setSelectedCards] = React.useState<number[]>([]);

  const [cardXOffsets, setCardXOffsets] = React.useState<number[]>([]);
  const [cardRotations, setCardRotations] = React.useState<number[]>([]);

  useEffect(() => {
    setSelectedCards(game?.player1.selectedCards || []);
    if (game?.table.cards.length === 0) {
      setCardXOffsets([]);
      setCardRotations([]);
    }
    if (game && game.table.cards.length > 0) {
      const newCardsCount = game.table.cards.length - cardXOffsets.length;
      const newCardXOffsets = cardXOffsets.slice();
      const newCardRotations = cardRotations.slice();
      const playedBy = game.table.playedBy;
      const tableOffset = game.table.cards.length - newCardsCount;
      var baseRotation = Number(getPlayerPos(game, playedBy[tableOffset])) * 90 + Math.random() * 20 - 10;
      for (let i = 0; i < newCardsCount; i++) {
        const xOffset = (i - (newCardsCount - 1) / 2) * .04 * td;
        const rotation = baseRotation + (i - (newCardsCount - 1) / 2) * 10 * Math.random();
        newCardXOffsets.push(xOffset);
        newCardRotations.push(rotation);
        if (playedBy[tableOffset + i + 1] !== playedBy[tableOffset + i]) {
          baseRotation = Number(getPlayerPos(game, playedBy[tableOffset + i + 1])) * 90 + Math.random() * 20 - 10;
        }
      };
      setCardXOffsets(newCardXOffsets);
      setCardRotations(newCardRotations);
    }
  }, [game]);

  const sendMove = () => {
    if (!game) return;
    onMove(game.player1.hand.filter((_, index) => selectedCards.includes(index)));
  }

  return (
  <div className={styles.gameTable}
    style={{
      width: `${td}px`,
      height: `${td}px`,
      left: '50%',
      transform: 'translateX(-50%)',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxSizing: 'border-box',
    }}>
      {game?.player1 && <Hand position={Position.BOTTOM} tableDimension={td} game={game} player={game.player1} selectedCards={selectedCards} setSelectedCards={setSelectedCards} />}
      {game?.player2 && <Hand position={Position.LEFT} tableDimension={td} game={game} player={game.player2} triggerMove={ () => sendMove() } />} {/*TODO: show selected cards of other players*/}
      {game?.player3 && <Hand position={Position.TOP} tableDimension={td} game={game} player={game.player3} triggerMove={ () => sendMove() } />}
      {game?.player4 && <Hand position={Position.RIGHT} tableDimension={td} game={game} player={game.player4} triggerMove={ () => sendMove() } />}
      <div className={styles.table}
        style={{
          position: 'absolute',
          width: `${.4 * td}px`,
          height: `${.4 * td}px`,
          bottom: `${.3 * td}px`,
          left: `${.3 * td}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: game?.currentPlayer?.playable ? 'pointer' : 'default',
        }}
        onClick={() => {
          if (!game?.currentPlayer?.playable) return;
          sendMove();
        }}
      >
        {/* Card placement indicator */}
        <div className={styles.cardPlaceholder}
        style={{
          width: `${.08 * td}px`,
          height: `${.08 * td * 1.5}px`,
          transform: game?.currentPlayer?.playable ? 'scale(1.1)' : 'scale(1)',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div style={{
            fontSize: `${.02 * td}px`,
            color: 'rgba(255,255,255,0.4)',
            textAlign: 'center',
            userSelect: 'none',
          }}>
            {game?.currentPlayer?.playable ? '♠♥♣♦' : ''}
          </div>
        </div>
        {game?.table.cards.map((card, index) => {
          if (card === -1) return null; // 'skip' card
          return (
            <img
              className='card'
              src={card === -1 ? cardBack : cardImages[card]}
              alt={`card ${card}`}
              style={{
                width: `${.08 * td}px`,
                height: `${.08 * td * 1.5}px`,
                borderRadius: `${.08 * td * .1}px`,
                border: '1px solid black',
                position: 'absolute',
                transform: `rotate(${cardRotations[index]}deg) translateX(${cardXOffsets[index]}px)`,
              }}
              key={index}
            />
          )
        })}
      </div>
      <div className={styles.tightInfo}
        style={{
          position: 'absolute',
          bottom: `${.35 * td}px`,
          left: '50%',
          transform: 'translateX(-50%)',
        }}>
        {game?.debug && <span>DEBUG<br /></span>}
        {game && game.revolution !== 0 && <span style={{color: 'rgb(255, 33, 33)'}}>{'Re'.repeat(game!.revolution)}volution<br /></span>}
        {game?.table.isTightRank && '# '}
        {game?.table.backward && (game.revolution % 2 === 1 ? '⇧ ' : '⇩ ')}
        {game?.table.tightSuits.map((suit, index) => (
          <span key={index} style={{marginRight: '2px'}}>
            {suit === Suit.DIAMONDS ? '♦'
            : suit === Suit.HEARTS ? '♥'
            : suit === Suit.SPADES ? '♠'
            : suit === Suit.CLUBS ? '♣'
            : ''}
          </span>
        ))}
      </div>

      {game?.gamePhase === 'DISCARD' && game?.currentPlayer?.playable && (
        <div 
          style={{
            position: 'absolute',
            width: `${.1 * td}px`,
            height: `${.1 * td}px`,
            bottom: `${0.32 * td}px`,
            right: `${0.32 * td}px`,
            borderRadius: `${.1 * td * .5}px`,
            border: '2px solid #ff4444',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            color: '#ff4444',
            fontSize: `${0.04 * td}px`,
            cursor: 'pointer',
          }}
          onClick={() => {
            sendMove();
          }}>
          🗑
        </div>
      )}

      {(!game || game.players.length < 4) && (
        <div className={styles.waitingIndicator}>
          Waiting for players... ({game?.players.length || 0}/4)
        </div>
      )}

      {(game?.gamePhase === 'END_ROUND' && (
        <div className={styles.waitingIndicator}>
          Next Round... ({game?.players.filter(player => player.ready).length || 0}/4)
        </div>
      ))}

      {(game?.gamePhase === 'END_ROUND' && game?.players.filter(player => player.playable && !player.ready).length > 0 && (
        <button className={styles.nextRoundButton}
          style={{
            position: 'absolute',
            bottom: `${.40 * td}px`,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          onClick={() => onMove([])}>
          Ready
        </button>
      ))}

    </div>
  );
}

function Hand({ position, tableDimension, game, player, selectedCards, setSelectedCards, triggerMove }: HandProps): ReactElement {
  const [hoveredCard, setHoveredCard] = React.useState<number | null>(null);

  const td = tableDimension;

  const handWidth = .5 * td;
  const handHeight = .2 * td;

  var cardWidth = .08 * td;
  var cardHeight = .08 * td * 1.5;
  var horizontalStep = .05 * td;
  if (position !== Position.BOTTOM) {
    cardWidth *= .8;
    cardHeight *= .8;
    horizontalStep *= .8;
  }

  const totalCards = player.hand.length || 0;
  const middleCardIndex = totalCards / 2;
  const cr = .9 * tableDimension; // radius of circle around which cards are arranged
  const bottomOffset = Math.sqrt(- ((horizontalStep * (- middleCardIndex) + .5 * cardWidth) ** 2) + cr ** 2);

  return (
    <div className={`${player.playable ? 'playable-hand' : 'non-playable-hand'}`}
      style={{
        bottom: `${position === Position.BOTTOM ? `${.05 * td}px` : position === Position.RIGHT ? `${.25 * td}px` : 'auto'}`,
        top: `${position === Position.TOP ? `${.05 * td}px` : position === Position.LEFT ? `${.25 * td}px` : 'auto'}`,
        left: `${position === Position.LEFT ? `${.05 * td}px` : position === Position.BOTTOM ? `${.25 * td}px` : 'auto'}`,
        right: `${position === Position.RIGHT ? `${.05 * td}px` : position === Position.TOP ? `${.25 * td}px` : 'auto'}`,
        position: 'absolute',
        display: 'flex',
        width: `${handWidth}px`,
        height: `${handHeight}px`,
        transform: `rotate(${position === Position.BOTTOM ? 0 : position === Position.TOP ? 180 : position === Position.LEFT ? 90 : -90}deg)
                    translateY(${position === Position.LEFT || position === Position.RIGHT ? .5 * handWidth - .5 * handHeight : 0}px)
                    translateX(${position === Position.LEFT || position === Position.RIGHT ? .5 * handWidth - .5 * handHeight : 0}px)`,
      }}>

      <div className={styles.playerNameContainer}
        style={{
          position: 'absolute',
          bottom: `${- .015 * td}px`,
          left: `${.5 * handWidth}px`,
          transform: 'translateX(-50%)',
        }}>
        <span className={styles.playerName}>{player.name || `Player ${player.uid}`}</span>
        {player.rank != 3 && (
          <div className={`${styles.rankBadgeSmall} ${
            player.rank === 5 ? styles.rankDaifugo 
            : player.rank === 4 ? styles.rankFugo
            : player.rank === 2 ? styles.rankHinmin
            : styles.rankDaihinmin
          }`}>
            {player.rank === 5 ? '大富' 
              : player.rank === 4 ? '富' 
              : player.rank === 2 ? '貧' 
              : '大貧'}
          </div>
        )}
      </div>

      {/* If player has finished the round, show rank */}
      {game?.finishedRound.includes(player.uid || '_') && (
        <div 
          className={`rank-display ${
            player.uid === game?.finishedRound[0] ? 'rank-daifugo' 
            : player.uid === game?.finishedRound[1] ? 'rank-fugo'
            : player.uid === game?.finishedRound[2] ? 'rank-hinmin'
            : 'rank-daihinmin'
          }`}
          style={{
            position: 'absolute',
            width: `${handHeight * 0.8}px`,
            height: `${handHeight * 0.4}px`,
            padding: `${handHeight * 0.05}px ${handHeight * 0.15}px`,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
          <div 
            className="rank-text"
            style={{
              fontSize: `${handHeight * 0.25}px`,
            }}>
            {player.uid === game?.finishedRound[0] ? '大富豪' 
              : player.uid === game?.finishedRound[1] ? '富豪' 
              : player.uid === game?.finishedRound[2] ? '貧民' 
              : '大貧民'}
          </div>
        </div>
      )}

      {/* If player has skipped, show 'skips' */}
      {game && player && game.skipCount > 0 && game.skippedPlayers.includes(player.uid) && player.hand.length > 0 && <div style={{
        position: 'absolute',
        width: `${handWidth}px`,
        height: '2em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1em',
        fontStyle: 'italic',
        borderRadius: `${.1 * handHeight}px`,
      }}>
        {game.rules.n5skip && game.table.currentRank === 5 && game.skippedPlayers.indexOf(player.uid) < game.table.playedTogether! ? 'skip' : 'pass'}
      </div>}
      {!game?.finishedRound.includes(player.uid || '_') && player.hand.map((card, index) => {
        const gradient = (index - middleCardIndex) * cardWidth / Math.sqrt(((index - middleCardIndex) * cardWidth + .5 * cardWidth) ** 2 + cr ** 2);
        const tilt = Math.atan(gradient) * (180 / Math.PI);
        const left = horizontalStep * (index - middleCardIndex);
        const bottom = Math.sqrt(- ((left + .5 * cardWidth) ** 2) + cr ** 2) - bottomOffset;
        const receivedCard = player.receivedCards.includes(index);

        const canSelect = player.playable && player.isPlayersTurn;

        return (
          <img
            key={index}
            className='card'
            src={card === -1 ? cardBack : cardImages[card]}
            alt={`card ${card}`}
            style={{
              position: 'absolute',
              width: `${cardWidth}px`,
              height: `${cardHeight}px`,
              left: `${.5 * handWidth + left}px`,
              bottom: `${hoveredCard === index || selectedCards?.includes(index) ? bottom + .03 * td : bottom}px`,
              transform: `rotate(${tilt}deg) scale(${hoveredCard === index || selectedCards?.includes(index) ? 1.1 : 1})`,
              zIndex: index,
              transition: 'all 0.1s ease',
              borderRadius: `${cardWidth * .1}px`,
              border: `${hoveredCard === index || selectedCards?.includes(index) 
                ? '2px solid black' 
                : receivedCard 
                  ? '4px solid darkgray'
                  : `2px solid hsl(${index * (360 / totalCards)}, 100%, ${player.isPlayersTurn ? '50%' : '90%'})`}`,
              cursor: canSelect ? 'pointer' : 'default',
            }}
            onMouseEnter={() => canSelect && setHoveredCard(index)}
            onMouseLeave={() => canSelect && setHoveredCard(null)}
            onClick={() => {
              //TODO: onCardSelect
              if (!selectedCards || !setSelectedCards) return;
              if (!canSelect) return;
              if (selectedCards.includes(index)) {
                setSelectedCards(selectedCards.filter(card => card !== index));
                setHoveredCard(null);
              } else if (!selectedCards.includes(index)) {
                setSelectedCards([...selectedCards, index]);
              }
            }}
          />
        );
      })}
      {(game?.gamePhase === 'GIVE' && game.nextPlayerUnfinished(game.currentPlayer!)!.uid === player.uid && game.currentPlayer?.playable
        || game?.gamePhase === 'EXCHANGE' && (game.player1.rank + player.rank) === 6 && game.player1.isPlayersTurn) && (
        <div className={styles.cardPlaceholder}
          style={{
            position: 'absolute',
            width: `${.09 * td}px`,
            height: `${.09 * td * 1.5}px`,
            left: `${.5 * handWidth - .045 * td}px`,
            bottom: `${.5 * handHeight - .045 * td * 1.8}px`,
            display: 'flex',
            zIndex: 100,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: `${.09 * td * .1}px`,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            color: 'black',
            fontSize: `${.02 * td}px`,
            cursor: 'pointer',
          }}
          onClick={() => {
            triggerMove && triggerMove();
          }}>
          GIVE
        </div>
      )}
    </div>
  );
}

export default SquaredDaifugoTable;