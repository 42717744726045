
abstract class Rules {

  static default(): Rules {
    return { minPlayers: 0, maxPlayers: 0, numPlayers: 0, botCompatible: false };
  }

  abstract get minPlayers(): number;
  abstract get maxPlayers(): number;

  abstract get numPlayers(): number | undefined;

  abstract get botCompatible(): boolean;
}

export default Rules;
