import MonkeysGame, { MonkeysPhase } from 'shared/game_logic/monkeys-game';
import MonkeysPlayer from 'shared/game_logic/monkeys-player';
import Card, { Suit } from 'shared/game_logic/card';
import React, { ReactElement, useEffect } from 'react';
import MonkeysMove, { MonkeysMoveType } from 'shared/game_logic/monkeys-move';
import ScoreBoard from './components/ScoreBoard';

import styles from './MonkeysTable.module.css';

const cardImages = Array.from({ length: 54 }, (_, i) => `/assets/cards/${i}.png`);
const cardBack = '/assets/cards/back.png';


interface MonkeysTableProps {
  dimension: number;
  game: MonkeysGame | undefined;
  onmove: (move: MonkeysMove) => void;
}

interface HandProps {
  position: number; // rotation in degrees
  tableDimension: number;
  game: MonkeysGame;
  player: MonkeysPlayer;
  ownSelectedCard: number | null;
  targetSelectedCard: [string, number] | null;
  setOwnSelectedCard?: (card: number | null) => void;
  setTargetSelectedCard?: (card: [string, number] | null) => void;
  onmove?: (move : MonkeysMove) => void;
}

function SquaredMonkeysTable({ dimension, game, onmove }: MonkeysTableProps): ReactElement {
  const td = dimension;

  const [ownSelectedCard, setOwnSelectedCard] = React.useState<number | null>(null);
  const [targetSelectedCard, setTargetSelectedCard] = React.useState<[string, number] | null>(null);

  useEffect(() => {
    if (game?.currentAction === 8 && ownSelectedCard !== null && onmove) {
      onmove(new MonkeysMove({ type: MonkeysMoveType.ACTION, targetCard: ownSelectedCard }));
      setOwnSelectedCard(null);
    }
    else if (game?.currentAction === 9 && targetSelectedCard !== null && onmove) {
      onmove(new MonkeysMove({ type: MonkeysMoveType.ACTION, targetUid: targetSelectedCard[0], targetCard: targetSelectedCard[1] }));
      setTargetSelectedCard(null);
    }
    else if (game?.currentAction === 10 && ownSelectedCard !== null && targetSelectedCard !== null && onmove) {
      onmove(new MonkeysMove({ type: MonkeysMoveType.ACTION, card: ownSelectedCard, targetUid: targetSelectedCard[0], targetCard: targetSelectedCard[1] }));
      setOwnSelectedCard(null);
      setTargetSelectedCard(null);
    }
  }, [ownSelectedCard, targetSelectedCard]);

  return (
    <div className={styles.gameTable}
      style={{
        width: `${td}px`,
        height: `${td}px`,
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {game?.players.map((player, index) => {
        return <Hand key={index}
                  position={index * 360 / game.players.length}
                  tableDimension={td} 
                  game={game}
                  player={player}
                  ownSelectedCard={ownSelectedCard}
                  targetSelectedCard={targetSelectedCard}
                  setOwnSelectedCard={player.playable && player.isPlayersTurn ? setOwnSelectedCard : undefined}
                  setTargetSelectedCard={!player.isPlayersTurn ? setTargetSelectedCard : undefined}
                  onmove={player.playable ? onmove : undefined}
                />
      })}

      <Deck dimension={td} deck={game?.deck || []} active={!!(game?.currentPlayer?.playable && game?.phase === MonkeysPhase.DRAW_CARD)} onmove={onmove} />

      <Discard dimension={td} cards={game?.discard || []} active={!!(game?.currentPlayer?.playable && game?.phase === MonkeysPhase.PLACE_CARD)} onmove={onmove} />

      <div className='info' style={{
        position: 'absolute',
        width: `${.1 * td}px`,
        height: `${.1 * td}px`,
        bottom: `${.35 * td}px`,
        left: `${.45 * td}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.5em',
        color: 'black',
      }}>
        {game?.debug && <span>DEBUG<br /></span>}
      </div>

      {game?.phase === MonkeysPhase.WAITING_FOR_PLAYERS && <WaitingForPlayers />}
      {game && <ScoreBoard 
        scores={game.scoreBoard || []} 
        players={game.players} 
        dimension={td} 
      />}
    </div>
  );
}

function WaitingForPlayers() {
  return (
    <div className='waitingForPlayers'
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '1em',
        borderRadius: '1em',
      }}>
      Waiting for players...
    </div>
  );
}

function Deck({ dimension, deck, active, onmove }: { dimension: number, deck: number[], active: boolean, onmove: (move: MonkeysMove) => void }): ReactElement {
  return (
    <div className='deck' style={{
      position: 'absolute',
      width: `${.09 * dimension}px`,
      height: `${.13 * dimension}px`,
      top: `${(dimension - .13 * dimension) / 2}px`,
      left: `${(dimension - .09 * dimension) / 2 - .08 * dimension}px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div style={{
        width: `${.08 * dimension}px`,
        height: `${.08 * dimension * 1.5}px`,
        backgroundColor: 'whitesmoke',
        borderRadius: '10px',
      }}></div>
      {deck.slice(0, -2).map((card, index) => {
        return <img
          className='card'
          src={cardBack}
          alt={`card ${card}`}
          style={{
            width: `${.08 * dimension}px`,
            height: `${.08 * dimension * 1.5}px`,
            borderRadius: `${.08 * dimension * .1}px`,
            border: '1px solid gray',
            position: 'absolute',
            transform: `rotate(${index * 5}deg)`,
          }}
          key={index}
        />
      })}
      {deck.length > 0 && <img
        className='card'
        src={cardBack}
        alt={`card ${deck[deck.length - 1]}`}
        style={{
          width: `${.08 * dimension}px`,
          height: `${.08 * dimension * 1.5}px`,
          borderRadius: `${.08 * dimension * .1}px`,
          border: `${active ? '2px solid black' : '2px solid gray'}`,
          position: 'absolute',
          transform: `rotate(${(deck.length - 1) * 5}deg)`,
          cursor: `${active ? 'pointer' : 'default'}`,
        }}
        onClick={() => {
          if (!active) return;
          onmove && onmove(new MonkeysMove({ type: MonkeysMoveType.DRAW }));
        }}
      />}
    </div>
  );
}

function Discard({ dimension, cards, active, onmove }: { dimension: number, cards: number[], active: boolean, onmove: (move: MonkeysMove) => void }): ReactElement {
  return (
    <div className='discard' style={{
      position: 'absolute',
      width: `${.09 * dimension}px`,
      top: `${(dimension - .13 * dimension) / 2}px`,
      height: `${.13 * dimension}px`,
      left: `${(dimension - .09 * dimension) / 2 + .08 * dimension}px`,
      border: '2px dashed gray',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: `${active ? 'pointer' : 'default'}`,
    }}
      onClick={() => {
        if (!active) return;
        onmove && onmove(new MonkeysMove({ type: MonkeysMoveType.PLACE, targetCard: -1 }));
      }}
    >
      <div style={{
        width: `${.08 * dimension}px`,
        height: `${.08 * dimension * 1.5}px`,
        backgroundColor: 'whitesmoke',
        borderRadius: '10px',
      }}></div>
      {cards.map((card, index) => {
        return (
          <img
            className='card'
            src={card === -1 ? cardBack : cardImages[card]}
            alt={`card ${card}`}
            style={{
              width: `${.08 * dimension}px`,
              height: `${.08 * dimension * 1.5}px`,
              borderRadius: `${.08 * dimension * .1}px`,
              border: '1px solid black',
              position: 'absolute',
            }}
            key={index}
          />
        )
      })}
    </div>
  );
}

function Hand({ position, tableDimension, game, player, ownSelectedCard, targetSelectedCard, setOwnSelectedCard, setTargetSelectedCard, onmove }: HandProps): ReactElement {
  const [hoveredCard, setHoveredCard] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (game?.currentPlayer?.uid !== player?.uid) {
      setHoveredCard(null);
    }
  }, [game]);

  const td = tableDimension;

  const handWidth = .3 * td;
  const handHeight = .2 * td;

  var cardWidth = .08 * td;
  var cardHeight = .08 * td * 1.5;
  var horizontalStep = cardWidth + .02 * td;
  if (position !== 0) {
    cardWidth *= .8; //TODO: even smaller depending on number of players
    cardHeight *= .8;
    horizontalStep *= .8;
  }

  const totalCards = player?.placedCards.length || 0;
  const middleCardIndex = (Math.ceil(totalCards / 2) - 1) / 2;
  const distToCenter = (td - handHeight) / 2; // radius
  const offsetX = distToCenter * Math.cos((position + 90) * Math.PI / 180);
  const offsetY = distToCenter * Math.sin((position + 90) * Math.PI / 180);

  const active = player.playable && player.isPlayersTurn && game.phase === MonkeysPhase.PLACE_CARD;
  const canDiscard = player.playable && player.isPlayersTurn && game.phase === MonkeysPhase.DRAW_CARD && game.deck && game.deck.length > 0 && game.discard && game.discard.length > 0;
  const detCanReveal = () => {
    if (!game.currentPlayer?.playable || game.phase !== MonkeysPhase.ACTION_TARGET) return false;
    if (game.currentPlayerUid === player.uid) {
      if (game.currentAction === 8) { return true; }
    } else {
      if (game.currentAction === 9) { return true; }
    }
    return false;
  }
  const canReveal = detCanReveal();
  const canSwap = game.currentPlayer?.playable && game.phase === MonkeysPhase.ACTION_TARGET && game.currentAction === 10 && !(game.lastRound && game.saidMonkeys == player.uid);

  return (
    <div className={`${player.playable ? 'playable-hand' : 'non-playable-hand'}`}
      style={{
        top: `${(td - handHeight) / 2}px`,
        left: `${(td - handWidth) / 2}px`,
        position: 'absolute',
        display: 'flex',
        width: `${handWidth}px`,
        height: `${handHeight}px`,
        transform: `translate(${offsetX}px, ${offsetY}px) rotate(${position}deg)`,
        transformOrigin: 'center center',
      }}>
      {player.placedCards.map((card, index) => {
        const left = (handWidth - cardWidth) / 2 + horizontalStep * (Math.floor(index / 2) - middleCardIndex);

        if (card === undefined || card === null) { // (de)serilization causes undefined to become null
          return;
        }

        const selectedAsOwn = game.currentPlayerUid === player.uid && ownSelectedCard !== null && ownSelectedCard === index;
        const selectedAsTarget = targetSelectedCard !== null && targetSelectedCard[0] === player.uid && targetSelectedCard[1] === index;

        const showActionResult = game.phase === 'ACTION_RESULT' && (game.currentAction === 10 || !game.currentPlayer?.playable);
        const isLastPlacedCard = game.lastPlacedCard && game.lastPlacedCard[0] === player.uid && game.lastPlacedCard[1] === index;
        const isRevealedCard = game.revealedCard && game.revealedCard[1] === player.uid && game.revealedCard[2] === index;
        const isSwappedCard = game.swappedCards && (game.swappedCards[0] === player.uid && game.swappedCards[1] === index || game.swappedCards[2] === player.uid && game.swappedCards[3] === index);
        const showPenaltyCard = game.penaltyCard && game.penaltyCard[0] === player.uid && game.penaltyCard[1] === index;
      
        return (
          <div key={index} className='card'
            style={{
              position: 'absolute',
              width: `${cardWidth}px`,
              height: `${cardHeight}px`,
              left: `${left}px`,
              bottom: `${index % 2 === 0 ? cardHeight + .025 * td : 0.01 * td}px`,
              transition: 'all 0.1s ease',
              backgroundImage: `url(${card === -1 ? cardBack : cardImages[card]})`,
              backgroundSize: 'cover',
              borderRadius: `${cardWidth * .1}px`,
              border: `${active && hoveredCard === index ? '2px solid black' : `2px solid hsl(${index * (360 / totalCards)}, 100%, ${active ? '50%' : '90%'})`}`,
              cursor: `${active ? 'pointer' : 'default'}`,
            }}
            onMouseEnter={() => setHoveredCard(index)}
            onMouseLeave={() => setHoveredCard(null)}
            onClick={() => {
              console.log(card);
              if (!active) return;
              onmove && onmove(new MonkeysMove({ type: MonkeysMoveType.PLACE, targetCard: index }));
            }}
          >
            {game.phase !== 'END_ROUND' && ((hoveredCard === index || selectedAsOwn || selectedAsTarget) && (canDiscard || canReveal || canSwap) || isLastPlacedCard && !game.playerByUid(game.lastPlacedCard[0])!.playable || showActionResult && (isRevealedCard || isSwappedCard) || showPenaltyCard) && (
              <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: `${canDiscard ? .035 * td : cardWidth}px`,
                height: `${canDiscard ? .035 * td : cardHeight}px`,
                background: `${canDiscard ? 'rgb(139, 0, 0)' : canReveal || isRevealedCard ? 'rgba(0, 100, 0, 0.5)' : isLastPlacedCard || canSwap || isSwappedCard ? 'rgba(0, 0, 139, 0.5)' : showPenaltyCard ? 'rgba(139, 0, 0, 0.5)' : 'transparent'}`,
                borderRadius: `${canDiscard ? '50%' : `${cardWidth * .1}px`}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: `${.025 * td}px`,
                cursor: `${canDiscard || canReveal || canSwap ? 'pointer' : 'default'}`,
              }}
              onClick={() => {
                if (!canDiscard && !canReveal && !canSwap) return;
                canDiscard && onmove && onmove(new MonkeysMove({ type: MonkeysMoveType.PLACE, targetCard: index }));
                (canReveal || canSwap) && setOwnSelectedCard && setOwnSelectedCard(index);
                (canReveal || canSwap) && setTargetSelectedCard && setTargetSelectedCard([player.uid, index]);
              }}>
                {canDiscard ? '⭮' : canReveal || isRevealedCard ? '👁' : canSwap || isSwappedCard ? '🔄' : ''}
              </div>
            )}
          </div>
        );
      })}
      {player && !player.playable && <span
        className='playerName'
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: `${cardHeight * 2 + .04 * td}px`,
          fontFamily: 'roboto',
          fontSize: `${.025 * td}px`,
          fontWeight: 700,
          color: 'rgba(255,255,255,0.95)',
          textAlign: 'center',
          background: 'linear-gradient(135deg, rgba(121,85,72,0.85), rgba(188,134,107,0.85))',
          padding: `${.008 * td}px ${.02 * td}px`,
          borderRadius: `${.015 * td}px`,
          boxShadow: '0 1px 4px rgba(0,0,0,0.15)',
          textShadow: '0 1px 1px rgba(0,0,0,0.25)',
          minWidth: `${.12 * td}px`,
          border: '1px solid rgba(255,255,255,0.1)',
          transition: 'all 0.2s ease',
          opacity: game.phase === 'START_ROUND' && !player.ready ? 0.7 : 0.9,
        }}
      >{player.name}</span>}
      {player.handCard !== undefined && player.handCard !== -2 && <img
        className='card'
        src={player.handCard === -1 ? cardBack : cardImages[player.handCard]}
        alt={`card ${player.handCard}`}
        style={{
          position: 'absolute',
          width: `${cardWidth}px`,
          height: `${cardHeight}px`,
          left: `${(handWidth - cardWidth) / 2 + horizontalStep * (Math.floor((totalCards - 1) / 2) + 1 - middleCardIndex) + .02 * td}px`,
          bottom: `${cardHeight / 2 + .02 * td}px`,
          borderRadius: `${cardWidth * .1}px`,
          border: '2px solid black',
        }}
      />}
      {(game.phase === 'START_ROUND' || game.phase === 'END_ROUND') && player.playable && <button
        style={{
          position: 'absolute',
          width: `${cardWidth}px`,
          height: `${cardHeight}px`,
          left: `${(handWidth - cardWidth) / 2 + horizontalStep * (Math.floor((totalCards - 1) / 2) + 1 - middleCardIndex) + .02 * td}px`,
          bottom: `${cardHeight / 2 + .02 * td}px`,
          cursor: 'pointer',
          opacity: player.ready ? 0.5 : 1,
          pointerEvents: player.ready ? 'none' : 'auto',
        }}
        onClick={() => {
          onmove && onmove(new MonkeysMove({ type: MonkeysMoveType.READY }));
        }
        }>Ready</button>}
      {(game && game.phase !== 'WAITING_FOR_PLAYERS' && game.phase !== 'START_ROUND' && game.phase !== 'END_ROUND' && !(player.isPlayersTurn && game.phase === 'PLACE_CARD') && player.playable || game?.saidMonkeys === player.uid && !(game?.phase === 'END_ROUND' && player.playable)) && <img
        className='monkeysButton'
        src='/assets/monkeys.svg'
        style={{
          position: 'absolute',
          width: `${cardWidth}px`,
          height: `${cardWidth}px`,
          left: `${(handWidth - cardWidth) / 2 + horizontalStep * (Math.floor((totalCards - 1) / 2) + 1 - middleCardIndex) + .02 * td}px`,
          bottom: `${cardHeight / 2 + .02 * td}px`,
          cursor: `${player.isPlayersTurn && game.phase === MonkeysPhase.DRAW_CARD && !game.lastRound ? 'pointer' : 'default'}`,
          opacity: player.isPlayersTurn && game.phase === MonkeysPhase.DRAW_CARD && !game.lastRound ? 1 : 0.5,
          fill: game.saidMonkeys === player.uid ? 'darkred' : 'black',
          filter: game.saidMonkeys === player.uid ? 'drop-shadow(0 0 4px darkred)' : 'none',
        }}
        onClick={() => {
          if (!player.isPlayersTurn) return;
          if (game.phase !== MonkeysPhase.DRAW_CARD) return;
          if (game.lastRound) return;
          onmove && onmove(new MonkeysMove({ type: MonkeysMoveType.MONKEYS }));
        }
        } />}
    </div>
  );
}

export default SquaredMonkeysTable;