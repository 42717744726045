import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css'; // Import the CSS file for styling
import useWindowDimensions from '../hooks/windowDimension';

const gameModes = [
  { name: 'Daifugo', img: '../assets/Daifugo.png', description: 'Start a Game of Daifugo!', link: '/Daifugo' },
  { name: 'Monkeys', img: '../assets/Monkeys.png', description: 'Start a Game of Monkeys!', link: '/Monkeys' },
  // Add more game modes as needed
];

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const scrollable = gameModes.length * (height / 3) > width;

  return (
    <div className="game-modes-wrapper">
      <div className="game-modes-container">
        {gameModes.map((mode, index) => (
          <div key={index} className={`game-mode ${scrollable ? "scroll" : ""}`} onClick={() => navigate(mode.link)}>
            <img src={mode.img} alt={mode.name} className="game-mode-image" />
            <div className="game-mode-description">{mode.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;