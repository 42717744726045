import { v4 as uuidv4 } from 'uuid';

interface PlayerOptions {
  name: string;
  uid?: string;
}

abstract class Player {
  private _name: string;
  private _uid: string;

  static get nameError(): string {
    return 'Username must be between 2 and 12 characters long and can only contain letters, numbers, and underscores';
  }

  static nameIsValid(username: string): boolean {
    return username.match(/^[a-zA-Z0-9あ-んア-ン一-龠_]{2,12}$/) !== null;
  }

  constructor(options: PlayerOptions) {
    this._name = options.name;
    this._uid = options.uid || uuidv4();
  }

  get uid(): string {
    return this._uid;
  }

  get name(): string {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
  }

  abstract get stateInfo(): string;
}

export default Player;