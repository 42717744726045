import React, { useEffect } from 'react';
import './Monkeys.css';

import useWindowDimensions from '../hooks/windowDimension';

import SquaredMonkeysTable from '../MonkeysTable';
import CreateOrJoinDialog from '../components/Dialog';
import { GameType } from 'shared/game_logic/game';
import MonkeysGame from 'shared/game_logic/monkeys-game';

import socket from '../socket';
import Rules from 'shared/game_logic/rules';

function Monkeys() {
  const { height, width } = useWindowDimensions();

  const [game, setGameState] = React.useState<MonkeysGame | undefined>(undefined);

  useEffect(() => {
    // Store socket.id in sessionStorage when connection established
    socket.on('connect', () => {
      const oldSocketId = sessionStorage.getItem('socketId');
      if (oldSocketId) {
        // Attempt to reconnect using the old socket ID
        console.log('Reconnecting with old socket ID:', oldSocketId);
        socket.emit('reconnect', oldSocketId, (game: object) => {
          console.log(game);
          setGameState(MonkeysGame.fromObject(game));
        });
      }
      // Save new socket ID
      if (socket.id) {
        sessionStorage.setItem('socketId', socket.id);
      }
    });
    socket.on('playerJoined', (username: string, newGameState: object) => {
      setGameState(MonkeysGame.fromObject(newGameState));
    });

    socket.on('gameStart', (newGameState: object) => {
      setGameState(MonkeysGame.fromObject(newGameState));
    });

    socket.on('gameUpdate', (newGameState: object) => {
      setGameState(MonkeysGame.fromObject(newGameState));
    });

    return () => {
      socket.off('connect');
      socket.off('playerJoined');
      socket.off('gameStart');
      socket.off('gameUpdate');
    }
  }, []);

  useEffect(() => {
    console.log(game);
  }, [game]);

  return (
    <div className="Monkeys">
      <CreateOrJoinDialog open={!game} gameType={GameType.MONKEYS} handleJoin={(username: string) => {
        socket.emit('join', {
          gameType: GameType.MONKEYS, 
          username: username
        }, (newGameState: object) => {
          setGameState(MonkeysGame.fromObject(newGameState));
          console.log(game);
        });
      }} handleCreate={(username: string, rules: Rules) => {
        const debug = window.location.search.includes('debug');
        socket.emit('create', {
          gameType: GameType.MONKEYS,
          username: username,
          rulesObj: rules,
          debug: debug
         }, (newGameState: object) => {
          setGameState(MonkeysGame.fromObject(newGameState));
          console.log(game);
        });
      }} />

      <SquaredMonkeysTable dimension={Math.min(height, width)} game={game} onmove={(move) => {
        console.log(move);
        socket.emit('move', move);
      }} />
    </div>
  );
}

export default Monkeys;
