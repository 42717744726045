
abstract class Move {
  private _type: string;

  constructor(type: string) {
    this._type = type;
  }

  get strType(): string {
    return this._type;
  }
}

export default Move;