import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DaifugoRules from 'shared/game_logic/daifugo-rules';
import { List, ListItem, ListItemText, MenuItem, Select, Switch, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Player from 'shared/game_logic/player';
import MonkeysRules from 'shared/game_logic/monkeys-rules';
import { GameType } from 'shared/game_logic/game';
import Rules from 'shared/game_logic/rules';

interface UsernameDialogProps {
  open: boolean;
  gameType: GameType;
  handleCreate: (username: string, rules: Rules) => void;
  handleJoin: (username: string) => void;
}

interface RulesSettingsProps {
  gameType: GameType;
  setRules: (rules: Rules) => void;
}

function checkUsername(username: string): boolean {
  return username.length > 0 && Player.nameIsValid(username);
}

function SimpleJoinDialog({ open, gameType, handleJoin }: UsernameDialogProps) {
  const [username, setUsername] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [error, setError] = useState('');

  return (
    <Dialog open={open}>
      <DialogTitle>Join Game</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Username"
          type="text"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (checkUsername(username)) {
                setError('');
                handleJoin(username);
              } else {
                setError(Player.nameError);
              }
            }
          }}
        />
        <TextField
          margin="dense"
          label="Invite Code"
          type="text"
          fullWidth
          value={inviteCode}
          onChange={(e) => setInviteCode(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (checkUsername(username)) {
                setError('');
                handleJoin(username);
              } else {
                setError(Player.nameError);
              }
            }
          }}
        />
        {error.length > 0 && <DialogContentText color="error">{error}</DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleJoin(username)}>Join</Button>
      </DialogActions>
    </Dialog>
  );
}


function CreateOrJoinDialog({ open, gameType, handleCreate, handleJoin }: UsernameDialogProps) {
  const [username, setUsername] = useState('');
  const [tab, setTab] = useState('join');
  const [rules, setRules] = useState<Rules>(gameType === GameType.DAIFUGO ? DaifugoRules.default() : MonkeysRules.default());
  const [error, setError] = useState('');
  const [debug, setDebug] = useState(window.location.search.includes('debug'));

  return (
    <Dialog open={open}>
      <DialogContent>
        <TabContext value={tab}>
          <TabList onChange={(e, value) => { setTab(value); setError(''); }}>
            <Tab label="Join" value="join" />
            <Tab label="Create" value="create" />
            {debug && <Tab label="Open Bot Game" value="bot" />}
          </TabList>
          <TabPanel value='join'>
            <TextField
              autoFocus
              margin="dense"
              label="Username"
              type="text"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (checkUsername(username)) {
                    setError('');
                    handleJoin(username);
                  } else {
                    setError(Player.nameError);
                  }
                }
              }}
            />
            {error.length > 0 && <DialogContentText color="error">{error}</DialogContentText>}
          </TabPanel>
          <TabPanel value='create'>
            <TextField
              autoFocus
              margin="dense"
              label="Username"
              type="text"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (checkUsername(username)) {
                    setError('');
                    handleCreate(username, rules);
                  } else {
                    setError(Player.nameError);
                  }
                }
              }}
            />
            {error.length > 0 && <DialogContentText color="error">{error}</DialogContentText>}
            <RulesSettings gameType={gameType} setRules={setRules} />
          </TabPanel>
          <TabPanel value='bot'>
            <TextField
              autoFocus
              margin="dense"
              label="Username"
              type="text"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  if (checkUsername(username)) {
                    setError('');
                    handleCreate(username, rules);
                  }
                }
              }
              }
            />
            {error.length > 0 && <DialogContentText color="error">{error}</DialogContentText>}
          </TabPanel>
        </TabContext>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          if (tab === 'create') {
            handleCreate(username, rules);
          } else {
            handleJoin(username);
          }
        }}>{tab === 'create' ? 'Create' : 'Join'}</Button>
      </DialogActions>
    </Dialog>
  );
}

// parse fields

function RulesSettings({ gameType, setRules }: RulesSettingsProps) {
  const defaultDaifugoRules = DaifugoRules.default();
  const defaultMonkeysRules = MonkeysRules.default();

  const [daifugoRules, setDaifugoRules] = useState({
    n5skip: defaultDaifugoRules.n5skip,
    n7give: defaultDaifugoRules.n7give,
    n8clear: defaultDaifugoRules.n8clear,
    n9reverse: defaultDaifugoRules.n9reverse,
    n10burn: defaultDaifugoRules.n10burn,
    n11back: defaultDaifugoRules.n11back,
    // joker: defaultDaifugoRules.joker,
    tightSuits: defaultDaifugoRules.tightSuits,
    tightRank: defaultDaifugoRules.tightRank,
    revolution: defaultDaifugoRules.revolution,
  })

  const [monkeysRules, setMonkeysRules] = useState({
    numPlayers: defaultMonkeysRules.numPlayers!,
    scoreToLose: defaultMonkeysRules.scoreToLose,
    penalty: defaultMonkeysRules.penalty,
    // TODO: numCards: defaultRules.numCards,
  })

  useEffect(() => {
    if (gameType === GameType.DAIFUGO) {
      setRules(new DaifugoRules(daifugoRules));
    } else if (gameType === GameType.MONKEYS) {
      setRules(new MonkeysRules(monkeysRules));
    }
  }, [daifugoRules, monkeysRules]);

  if (gameType === GameType.DAIFUGO) {
    return (
      <List
        sx={{ width: '100%' }}
        subheader="Game Rules"
      >
        <ListItem>
          <ListItemText primary="5 skips" secondary="Players can skip next player(s) by playing one or more 5s" />
          <Switch checked={daifugoRules.n5skip} onChange={(e) => setDaifugoRules({ ...daifugoRules, n5skip: e.target.checked })} />
        </ListItem>
        <ListItem>
          <ListItemText primary="7 gives" secondary="Players can give up to k cards to the next player by playing k 7s" />
          <Switch checked={daifugoRules.n7give} onChange={(e) => setDaifugoRules({ ...daifugoRules, n7give: e.target.checked })} />
        </ListItem>
        <ListItem>
          <ListItemText primary="8 clears" secondary="Players can clear the table by playing one or more 8s" />
          <Switch checked={daifugoRules.n8clear} onChange={(e) => setDaifugoRules({ ...daifugoRules, n8clear: e.target.checked })} />
        </ListItem>
        <ListItem>
          <ListItemText primary="9 reverses" secondary="Players can reverse the playing direction by playing one or more 9s" />
          <Switch checked={daifugoRules.n9reverse} onChange={(e) => setDaifugoRules({ ...daifugoRules, n9reverse: e.target.checked })} />
        </ListItem>
        <ListItem>
          <ListItemText primary="10 burns" secondary="Players can burn up to k cards by playing k 10s" />
          <Switch checked={daifugoRules.n10burn} onChange={(e) => setDaifugoRules({ ...daifugoRules, n10burn: e.target.checked })} />
        </ListItem>
        <ListItem>
          <ListItemText primary="11 backs" secondary="Players can reverse the order by playing one or more Js" />
          <Switch checked={daifugoRules.n11back} onChange={(e) => setDaifugoRules({ ...daifugoRules, n11back: e.target.checked })} />
        </ListItem>
        {/* <ListItem>
          <ListItemText primary="Jokers" secondary="Jokers are included in the deck" />
          <Switch checked={daifugoRules.joker} onChange={(e) => setDaifugoRules({ ...daifugoRules, joker: e.target.checked })} />
        </ListItem> */}
        <ListItem>
          <ListItemText primary="Tight suits" secondary="Repeating suits will be locked (tight) for the round" />
          <Switch checked={daifugoRules.tightSuits} onChange={(e) => setDaifugoRules({ ...daifugoRules, tightSuits: e.target.checked })} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Tight rank" secondary="Directly succeeding ranks will be locked (tight) for the round" />
          <Switch checked={daifugoRules.tightRank} onChange={(e) => setDaifugoRules({ ...daifugoRules, tightRank: e.target.checked })} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Revolution" secondary="Four cards of the same rank played together will reverse the playing order for the rest of the game" />
          <Switch checked={daifugoRules.revolution} onChange={(e) => setDaifugoRules({ ...daifugoRules, revolution: e.target.checked })} />
        </ListItem>
      </List>
    );
  }
  else if (gameType === GameType.MONKEYS) {
    return (
      <List
        sx={{ width: '100%' }}
        subheader="Game Rules"
      >
        <ListItem>
          <ListItemText primary="Number of players" secondary="Number of players in the game" />
          <Select
            value={monkeysRules.numPlayers}
            onChange={(e) => setMonkeysRules({ ...monkeysRules, numPlayers: e.target.value as number })}
          >
            {Array.from({ length: defaultMonkeysRules.maxPlayers - defaultMonkeysRules.minPlayers + 1 }, (_, i) => (
              <MenuItem key={i + defaultMonkeysRules.minPlayers} value={i + defaultMonkeysRules.minPlayers}>
                {i + defaultMonkeysRules.minPlayers}
              </MenuItem>
            ))}
          </Select>
        </ListItem>
      </List>
    );
  }
  return <></>;
}

export default CreateOrJoinDialog;