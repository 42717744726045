import Move from "./move";

enum DaifugoMoveType {
  READY = 'ready',
  PLAY = 'play',
  GIVE = 'give',
  DISCARD = 'discard',
  EXCHANGE = 'exchange',
}

interface DaifugoMoveOptions {
  type?: DaifugoMoveType;
  cards?: number[];
}

class DaifugoMove extends Move {
  private _cards: number[];

  static fromObject(obj: any): DaifugoMove {
    return new DaifugoMove({
      type: obj._type,
      cards: obj._cards,
    });
  }

  constructor(options: DaifugoMoveOptions) {
    super(options.type?.toString() || DaifugoMoveType.PLAY.toString());
    this._cards = options.cards || [];
  }

  get strType(): string {
    return this.type.toString();
  }

  get type(): DaifugoMoveType {
    const type = super.strType as DaifugoMoveType;
    if (!type) {
      throw new Error('Invalid DaifugoMoveType');
    }
    return type;
  }

  get cards(): number[] {
    return this._cards;
  }
}

export { DaifugoMoveType };
export default DaifugoMove;