import { v4 as uuidv4 } from 'uuid';
import Player from './player';

export enum PlayerRank {
  DAIHINMIN = 1,
  HINMIN = 2,
  HEIMIN = 3,
  FUGO = 4,
  DAIFUGO = 5,
}

interface DaifugoPlayerOptions {
  name: string;
  uid?: string;
  playable?: boolean;
  rank?: PlayerRank;
  hand?: number[];
  receivedCards?: number[];
  selectedCards?: number[];
  isPlayersTurn?: boolean;
  ready?: boolean;
}


class DaifugoPlayer extends Player {
  private _playable: boolean;
  private _rank: PlayerRank;
  private _hand: number[];
  private _receivedCards: number[];
  private _selectedCards: number[];
  private _isPlayersTurn: boolean;

  private _ready: boolean;

  static fromObject(obj: any): DaifugoPlayer {
    return new DaifugoPlayer({
      name: obj._name,
      uid: obj._uid,
      playable: obj._playable,
      rank: obj._rank,
      hand: obj._hand,
      receivedCards: obj._receivedCards,
      selectedCards: obj._selectedCards,
      isPlayersTurn: obj._isPlayersTurn,
      ready: obj._ready,
    });
  }

  constructor(options: DaifugoPlayerOptions) {
    super({ name: options.name, uid: options.uid || uuidv4() });
    this._playable = options.playable || false;
    this._rank = options.rank || PlayerRank.HEIMIN;
    this._hand = options.hand || [];
    this._receivedCards = options.receivedCards || [];
    this._selectedCards = options.selectedCards || [];
    this._isPlayersTurn = options.isPlayersTurn || false;
    this._ready = options.ready === undefined ? true : options.ready; // born ready
  }

  maskedState(playerUid: string, debug: boolean): object {
    return {
      _uid: this.uid,
      _name: this.name,
      _rank: this._rank,
      _playable: this.uid === playerUid ? true : false,
      _hand: this.uid === playerUid || debug ? this.hand : this.hand.map(() => -1),
      _receivedCards: this.receivedCards,
      _selectedCards: this.uid === playerUid ? this.selectedCards : [],
      _isPlayersTurn: this.isPlayersTurn,
      _ready: this._ready,
    };
  }

  get rank(): PlayerRank {
    return this._rank;
  }

  set rank(rank: PlayerRank) {
    this._rank = rank;
  }

  get hand(): number[] {
    return this._hand;
  }

  set hand(hand: number[]) {
    this._hand = hand;
  }

  get receivedCards(): number[] {
    return this._receivedCards;
  }

  set receivedCards(receivedCards: number[]) {
    this._receivedCards = receivedCards;
  }

  get selectedCards(): number[] {
    return this._selectedCards;
  }

  set selectedCards(selectedCards: number[]) {
    this._selectedCards = selectedCards;
  }

  deselectCard(card: number): void {
    this._selectedCards = this._selectedCards.filter(c => c !== card);
  }

  deselectAll(): void {
    this._selectedCards = [];
  }

  get playable(): boolean {
    return this._playable;
  }

  get isPlayersTurn(): boolean {
    return this._isPlayersTurn;
  }

  set isPlayersTurn(isPlayersTurn: boolean) {
    this._isPlayersTurn = isPlayersTurn;
  }

  get ready(): boolean {
    return this._ready;
  }

  set ready(ready: boolean) {
    this._ready = ready;
  }

  get stateInfo(): string {
    return `${this.name} (${this.hand.length} cards)`;
  }
}

export default DaifugoPlayer;