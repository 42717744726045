import Game from "./game";
import MonkeysMove, { MonkeysMoveType } from "./monkeys-move";
import MonkeysPlayer from "./monkeys-player";
import MonkeysRules from "./monkeys-rules";
import Move from "./move";

enum MonkeysPhase {
  WAITING_FOR_PLAYERS = 'WAITING_FOR_PLAYERS',
  START_ROUND = 'START_ROUND',
  DRAW_CARD = 'DRAW_CARD',
  PLACE_CARD = 'PLACE_CARD',
  ACTION_TARGET = 'ACTION_TARGET',
  ACTION_RESULT = 'ACTION_RESULT',
  REVEAL_CARD = 'REVEAL_CARD',
  MISPLACED_CARD = 'MISPLACED_CARD',
  LAST_ROUND = 'LAST_ROUND',
  END_ROUND = 'END_ROUND',
  OVER = 'OVER',
}

type PlacedCard = [string, number];
type RevealedCard = [string, string, number];
type SwappedCards = [string, number, string, number];
type PlayerScores = { [playerUid: string]: number };

interface MonkeysOptions {
  players: MonkeysPlayer[];
  currentPlayerUid?: string;
  phase?: MonkeysPhase;
  deck?: number[];
  discard?: number[];

  rules?: MonkeysRules;

  saidMonkeys?: string;
  currentAction?: 8 | 9 | 10;
  lastPlacedCard?: PlacedCard | undefined;
  revealedCard?: RevealedCard | undefined;
  swappedCards?: SwappedCards | undefined;
  penaltyCard?: PlacedCard | undefined;

  scoreBoard?: PlayerScores[];

  debug?: boolean;
}

class MonkeysGame extends Game {
  private _currentPlayerUid?: string;
  private _phase: MonkeysPhase;
  private _deck?: number[];
  private _discard?: number[];

  private _saidMonkeys?: string;
  private _currentAction?: 8 | 9 | 10;
  private _lastPlacedCard?: PlacedCard;
  private _revealedCard?: RevealedCard;
  private _swappedCards?: SwappedCards;
  private _penaltyCard?: PlacedCard;

  private _scoreBoard: PlayerScores[];

  static fromObject(obj: any): MonkeysGame {
    return new MonkeysGame({
      players: obj._players.map((player: any) => MonkeysPlayer.fromObject(player)),
      currentPlayerUid: obj._currentPlayerUid,
      phase: obj._phase,
      deck: obj._deck,
      discard: obj._discard,
      rules: MonkeysRules.fromObject(obj._rules),
      saidMonkeys: obj._saidMonkeys,
      debug: obj._debug,
      currentAction: obj._currentAction,
      lastPlacedCard: obj._lastPlacedCard,
      revealedCard: obj._revealedCard,
      swappedCards: obj._swappedCards,
      penaltyCard: obj._penaltyCard,
      scoreBoard: obj._scoreBoard,
    });
  }

  constructor(options: MonkeysOptions) {
    super({ debug: options.debug, rules: options.rules || MonkeysRules.default() });
    for (const player of options.players) {
      this.addPlayer(player);
    }
    this._currentPlayerUid = options.currentPlayerUid;
    this._phase = options.phase || MonkeysPhase.WAITING_FOR_PLAYERS;
    this._deck = options.deck;
    this._discard = options.discard;
    this._saidMonkeys = options.saidMonkeys;
    this._currentAction = options.currentAction;
    this._lastPlacedCard = options.lastPlacedCard;
    this._revealedCard = options.revealedCard;
    this._swappedCards = options.swappedCards;
    this._penaltyCard = options.penaltyCard;
    this._scoreBoard = options.scoreBoard || [];
  }

  maskedGameStateObject(playerUid: string): object {
    const player = this.playerByUid(playerUid);
    if (player === undefined) {
      return {};
    }
    const misplacedCard = this._phase === MonkeysPhase.MISPLACED_CARD ? this._lastPlacedCard : undefined;
    const startRound = this._phase === MonkeysPhase.START_ROUND;
    const endRound = this._phase === MonkeysPhase.END_ROUND;
    const players = [player.maskedState(playerUid, startRound, endRound, this._revealedCard, misplacedCard)];
    for (var p = player; this.nextPlayer(p.uid) !== player; ) {
      const nextPlayer = this.nextPlayer(p.uid);
      if (nextPlayer === undefined) {
        break;
      }
      p = nextPlayer;
      players.push(nextPlayer.maskedState(playerUid, startRound, endRound, this._revealedCard, misplacedCard));
    }
    return {
      _players: players,
      _currentPlayerUid: this._currentPlayerUid,
      _phase: this._phase,
      _deck: this._deck?.map(() => -1),
      _discard: this._discard,
      _rules: this.rules,
      _saidMonkeys: this._saidMonkeys,
      _currentAction: this._currentAction,
      _lastPlacedCard: this._lastPlacedCard,
      _revealedCard: this._revealedCard,
      _swappedCards: this._swappedCards,
      _penaltyCard: this._penaltyCard,
      _scoreBoard: this._scoreBoard,
      _debug: this.debug,
    };
  }

  get players(): MonkeysPlayer[] {
    return super.players as MonkeysPlayer[];
  }

  playerByUid(uid: string): MonkeysPlayer | undefined {
    return super.playerByUid(uid) as MonkeysPlayer;
  }

  addPlayer(player: MonkeysPlayer): void {
    super.addPlayer(player);
  }

  newPlayer(name: string): MonkeysPlayer | undefined {
    if (this.players.length >= (this.rules.numPlayers || this.rules.maxPlayers)) {
      console.error('Game is full');
      return;
    }
    const player = new MonkeysPlayer({ name });
    this.addPlayer(player);
    return player;
  }

  get rules(): MonkeysRules {
    return super.rules as MonkeysRules;
  }

  get currentPlayerUid(): string | undefined {
    return this._currentPlayerUid;
  }

  get currentPlayer(): MonkeysPlayer | undefined {
    if (this._currentPlayerUid === undefined)
      return undefined;
    return this.playerByUid(this._currentPlayerUid);
  }

  get previousPlayer(): MonkeysPlayer | undefined {
    if (this.currentPlayer === undefined) {
      return;
    }
    const index = this.players.indexOf(this.currentPlayer);
    return this.players[(index - 1 + this.players.length) % this.players.length];
  }

  private set currentPlayer(player: MonkeysPlayer | undefined) {
    if (this.currentPlayer !== undefined) {
      this.currentPlayer.isPlayersTurn = false;
    }
    this._currentPlayerUid = player?.uid;
    if (player === undefined) {
      return;
    }
    player.isPlayersTurn = true;
  }

  get phase(): MonkeysPhase {
    return this._phase;
  }

  get saidMonkeys(): string | undefined {
    return this._saidMonkeys;
  }

  get currentAction(): 8 | 9 | 10 | undefined {
    return this._currentAction;
  }

  get lastPlacedCard(): PlacedCard | undefined {
    return this._lastPlacedCard;
  }

  get revealedCard(): RevealedCard | undefined {
    return this._revealedCard;
  }

  get swappedCards(): SwappedCards | undefined {
    return this._swappedCards;
  }

  get penaltyCard(): PlacedCard | undefined {
    return this._penaltyCard;
  }

  get lastRound(): boolean {
    return this._saidMonkeys !== undefined;
  }

  get readyToStart(): boolean {
    return this.players.length === this.rules.numPlayers;
  }

  get gameOngoing(): boolean {
    return this._phase !== MonkeysPhase.WAITING_FOR_PLAYERS && this._phase !== MonkeysPhase.OVER;
  }

  get gameOver(): boolean {
    return this._phase === MonkeysPhase.OVER;
  }

  get playersReady(): number {
    return this.players.filter(player => player.ready).length;
  }

  get stateInfo(): string {
    return this.phase + ', players ' + this.players.map(player => player.stateInfo).join(' ');
  }

  get deck(): number[] | undefined {
    return this._deck;
  }

  get discard(): number[] | undefined {
    return this._discard;
  }

  set phase(phase: MonkeysPhase) {
    this._phase = phase;
  }

  set deck(deck: number[] | undefined) {
    this._deck = deck;
  }

  set discard(discard: number[] | undefined) {
    this._discard = discard;
  }

  get scoreBoard(): PlayerScores[] {
    return this._scoreBoard;
  }

  get roundsPlayed(): number {
    return this._scoreBoard.length;
  }

  get debug(): boolean {
    return super.debug;
  }

  /**
   * Resets round-specific variables and starts a new round
  */
  deal(): void {
    if (!this.players || this.players.length < (this.rules.numPlayers || this.rules.minPlayers)) {
      console.error('Not enough players to start game');
      return;
    }

    if (this.players.length > (this.rules.numPlayers || this.rules.maxPlayers)) {
      console.error('Too many players in game');
      return;
    }
    
    const deck = Array.from({ length: 52 }, (_, i) => i);

    // Shuffle the deck
    for (let i = deck.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [deck[i], deck[j]] = [deck[j], deck[i]];
    }

    for (const p of this.players) {
      p.deal(deck.splice(0, 4));
    }

    this._deck = deck;

    this._discard = [];

    this._phase = MonkeysPhase.START_ROUND;

    this._saidMonkeys = undefined;

    for (const player of this.players) {
      player.ready = false;
    }
  }

  nextPlayer(playerUid: string): MonkeysPlayer | undefined {
    const player = this.playerByUid(playerUid);
    if (player === undefined) {
      return;
    }
    const index = this.players.indexOf(player);
    return this.players[(index + 1) % this.players.length];
  }

  drawCard(playerUid: string): void {
    if (this._deck === undefined) {
      return;
    }
    const card = this._deck.pop();
    if (card === undefined) {
      return;
    }
    const player = this.playerByUid(playerUid);
    if (player === undefined) {
      return;
    }
    player.drawCard(card);
  }

  playMove(playerUid: string, move_: Move, updateCallback: () => void, msgCallback: (msg: string, toAll: boolean) => void): boolean {
    if (!(move_ instanceof MonkeysMove)) {
      throw new Error('Invalid move');
    }
    const move = move_ as MonkeysMove;
    if (this._phase === MonkeysPhase.WAITING_FOR_PLAYERS) {
      console.error('Game not started');
      return false;
    }

    if (this._phase === MonkeysPhase.START_ROUND) {
      if (move.type === MonkeysMoveType.READY) {
        const player = this.playerByUid(playerUid);
        if (player === undefined || player.ready) {
          console.error('Player not found or already ready');
          return false;
        }
        player.ready = true;
        console.log('Player', player.name, 'is ready');
        if (this.playersReady === this.players.length) {
          this._phase = MonkeysPhase.DRAW_CARD;
          this.currentPlayer = this.players[0];
        }
        this._lastPlacedCard = undefined;
        this._penaltyCard = undefined;
        return true;
      }
    }

    if (this._phase === MonkeysPhase.DRAW_CARD) {
      if (move.type === MonkeysMoveType.DRAW) {
        this.drawCard(playerUid);
        this._phase = MonkeysPhase.PLACE_CARD;
        this._lastPlacedCard = undefined;
        this._penaltyCard = undefined;
        return true;
      }
      if (move.type === MonkeysMoveType.MONKEYS) {
        this._saidMonkeys = playerUid;
        this._phase = MonkeysPhase.DRAW_CARD;
        this.currentPlayer = this.nextPlayer(playerUid);
        this._lastPlacedCard = undefined;
        this._penaltyCard = undefined;
        return true;
      }
      if (move.type === MonkeysMoveType.PLACE) {
        if (move.targetCard === undefined) {
          console.error('No target card');
          return false;
        }
        
        this._penaltyCard = undefined;

        const card = this.discardCard(playerUid, move.targetCard);

        if (card === undefined) {
          return false;
        }

        const cardRank = card === -1 ? -1 : card % 13 + 1;

        this._lastPlacedCard = [playerUid, move.targetCard];

        if (card === -1) {
          this._phase = MonkeysPhase.MISPLACED_CARD;
          setTimeout(() => {
            this._phase = MonkeysPhase.DRAW_CARD;
            this.currentPlayer = this.nextPlayer(playerUid);
            this.checkEndRound();
            updateCallback();
          }, 2000);
          return true;
        }
        else if (cardRank === 8 || cardRank === 9 || (cardRank === 10 && !(this.players.length === 2 && this.lastRound))) {
          this._currentAction = cardRank as 8 | 9 | 10;
          this._phase = MonkeysPhase.ACTION_TARGET;
          return true;
        }
        else {
          this._phase = MonkeysPhase.DRAW_CARD;
          this.currentPlayer = this.nextPlayer(playerUid);
          this.checkEndRound();
          return true;
        }
      }
    }

    if (this._phase === MonkeysPhase.PLACE_CARD) {
      if (move.type === MonkeysMoveType.PLACE) {
        if (move.targetCard === undefined) {
          console.error('No target card');
          return false;
        }

        const card = this.playCard(playerUid, move.targetCard);
        
        if (card === undefined) {
          return false;
        }

        const cardRank = card % 13 + 1;

        this._lastPlacedCard = [playerUid, move.targetCard];

        if (cardRank === 8 || cardRank === 9 || (cardRank === 10 && !(this.players.length === 2 && this.lastRound))) {
          this._currentAction = cardRank as 8 | 9 | 10;
          this._phase = MonkeysPhase.ACTION_TARGET;

          return true;
        }
        else {
          this._phase = MonkeysPhase.DRAW_CARD;
          this.currentPlayer = this.nextPlayer(playerUid);
          this.checkEndRound();

          return true;
        }
      }
    }

    if (this._phase === MonkeysPhase.ACTION_TARGET) {
      if (move.type === MonkeysMoveType.ACTION) {
        if (move.targetCard === undefined) {
          console.error('No target card');
          return false;
        }

        if (!this._currentAction) {
          console.error('Card action type not set');
        }

        if (this._currentAction === 8) {
          if (!this.revealCard(playerUid, playerUid, move.targetCard)) {
            return false;
          }
        }
        else if (this._currentAction === 9) {
          if (move.targetUid === undefined) {
            console.error('Target player missing in move');
            return false;
          }
          if (!this.revealCard(playerUid, move.targetUid, move.targetCard)) {
            return false;
          }
        }
        else if (this._currentAction === 10) {
          if (move.card === undefined) {
            console.error('Card position missing in move');
            return false;
          }
          if (move.targetUid === undefined) {
            console.error('Target player missing in move');
            return false;
          }
          if (!this.swapCard(playerUid, move.card, move.targetUid, move.targetCard)) {
            return false;
          }
        }

        this._phase = MonkeysPhase.ACTION_RESULT;

        setTimeout(() => {
          this._currentAction = undefined;
          this._revealedCard = undefined;
          this._swappedCards = undefined;
          this._phase = MonkeysPhase.DRAW_CARD;
          this.currentPlayer = this.nextPlayer(playerUid);
          this.checkEndRound();
          updateCallback();
        }, 2000);

        this._lastPlacedCard = undefined;

        return true;
      }
    }

    if (this._phase === MonkeysPhase.END_ROUND) {
      if (move.type === MonkeysMoveType.READY) {
        const player = this.playerByUid(playerUid);
        if (player === undefined || player.ready) {
          console.error('Player not found or already ready');
          return false;
        }
        player.ready = true;
        if (this.playersReady === this.players.length) {
          this.deal();
        }
        return true;
      }
    }

    return false;
  }

  playCard(playerUid: string, position: number): number | undefined {
    const player = this.playerByUid(playerUid);
    if (player === undefined) {
      console.error('Player not found');
      return undefined;
    }
    const card = player.playCard(position);

    if (card === -1) {
      return undefined;
    }

    if (this._discard === undefined) {
      console.error('Discard pile not found');
      return undefined;
    }

    this._discard.push(card);

    return card;
  }

  discardCard(playerUid: string, position: number): number | undefined {
    const player = this.playerByUid(playerUid);
    if (player === undefined) {
      console.error('Player not found');
      return undefined;
    }
    
    const card = player.placedCards[position];

    if (card === undefined) {
      console.error('No card at position: ', position);
      return undefined;
    }

    const discardPileCard = this._discard?.[this._discard.length - 1];

    if (discardPileCard === undefined) {
      console.error('Discard pile is empty');
      return undefined;
    }

    if (card % 13 !== discardPileCard % 13) {
      console.log('Card rank', card % 13 + 1, 'did not match discard pile rank', discardPileCard % 13 + 1);

      if (!this.deck || this.deck.length === 0) {
        console.log('Player', player.name, 'needed to add a card to the placed cards but the deck is empty');
        return -1;
      }

      const addCard = this.deck.pop()!;

      if (player.placedCards.includes(undefined)) {
        player.placedCards[player.placedCards.indexOf(undefined)] = addCard;
      } else {
        player.placedCards.push(addCard);
      }

      this._penaltyCard = [playerUid, player.placedCards.indexOf(addCard)];

      return -1;
    }

    this._discard!.push(player.discardCard(position)!);

    return card;
  }

  revealCard(playerUid: string, targetPlayerUid: string, position: number): boolean {
    if (this._phase !== MonkeysPhase.ACTION_TARGET) {
      console.error('Not in action target phase');
      return false;
    }

    const player = this.playerByUid(playerUid);

    if (player === undefined) {
      console.error('Player not found');
      return false;
    }

    const targetPlayer = this.playerByUid(targetPlayerUid);

    if (targetPlayer === undefined) {
      console.error('Target player not found');
      return false;
    }

    const card = targetPlayer.placedCards[position];

    if (card === undefined) {
      console.error('No card at position: ', position);
      return false;
    }

    this._revealedCard = [playerUid, targetPlayerUid, position];

    return true;
  }

  swapCard(playerUid: string, position: number, targetPlayerUid: string, targetPosition: number): boolean {
    if (this._phase !== MonkeysPhase.ACTION_TARGET) {
      console.error('Not in action target phase');
      return false;
    }

    const player = this.playerByUid(playerUid);

    if (player === undefined) {
      console.error('Player not found');
      return false;
    }

    const targetPlayer = this.playerByUid(targetPlayerUid);

    if (targetPlayer === undefined) {
      console.error('Target player not found');
      return false;
    }

    const card = player.placedCards[position];

    if (card === undefined) {
      console.error('No card at position: ', position);
      return false;
    }

    const targetCard = targetPlayer.placedCards[targetPosition];

    if (targetCard === undefined) {
      console.error('No card at position: ', targetPosition);
      return false;
    }

    player.placedCards[position] = targetCard;
    targetPlayer.placedCards[targetPosition] = card;

    this._swappedCards = [playerUid, position, targetPlayerUid, targetPosition];

    return true;
  }


  checkEndRound(): boolean {
    if (this._deck?.length === 0 || this._saidMonkeys === this.currentPlayerUid) {
      this._phase = MonkeysPhase.END_ROUND;
      this.currentPlayer = undefined;
      this.calculateScores();

      for (const player of this.players) {
        player.ready = false;
      }

      return true;
    }

    return false;
  }

  calculateScores(): void {
    const monke = this.playerByUid(this._saidMonkeys!)!;
    const otherPlayers = this.players.filter(player => player !== monke);
    const monkeWon = monke.currentScore < otherPlayers.reduce((max, player) => Math.max(max, player.currentScore), 0);
    monke.score += monkeWon ? 0 : monke.currentScore + 20;

    const scores = { [monke.uid]: monke.score };
    for (const player of otherPlayers) {
      player.score += player.currentScore;
      scores[player.uid] = player.score;
    }

    this._scoreBoard.push(scores);
  }

  checkEndGame(): boolean {
    if (this.rules.maxRounds && this.roundsPlayed >= this.rules.maxRounds) {
      this._phase = MonkeysPhase.OVER;
      return true;
    }

    if (this.players.some(player => player.score >= this.rules.scoreToLose)) {
      this._phase = MonkeysPhase.OVER;
      return true;
    }

    return false;
  }

}

export { MonkeysPhase, PlacedCard, RevealedCard, SwappedCards };
export default MonkeysGame;